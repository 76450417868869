import React, { useState, useRef, useEffect } from "react";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { createAxiosInstance as myAxios } from "../services/Helper";
import { useNavigate } from "react-router-dom";
import { ImKey2 } from "react-icons/im";
import "primeicons/primeicons.css";
import "./VaultSecret.css";
import { Password } from "primereact/password";
import { FloatLabel } from "primereact/floatlabel";
import { checkEmptyFieldForVault } from "../common/handleEmptyField";
import {
  DBCreate,
  SFTPCreate,
} from "../API Services/VaultSecrets/CreateSecretsService";
import { handleEncryptedData } from "../Encrypt API/ModifiedEncrypt";
import ConnectionTestDialog from "../Dialog/ConnectionTestDialog";
import { Accordion, AccordionTab } from "primereact/accordion";

export default function CreateSecret() {
  const [sftpVisible, setSftpVisible] = useState(false);
  const [sftptestvisible, setSftpTestVisible] = useState(false);
  const [dbtestvisible, setDbTestVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [dbVisible, setDbVisible] = useState(false);
  const [pgpVisible, setPgpVisible] = useState(false);
  const [sftpError, setSftpError] = useState({});
  const [dbError, setDbError] = useState({});
  const [pgpError, setPgpError] = useState([]);
  const navigate = useNavigate();

  //Only for Admin
  const [isAdmin, setIsAdmin] = useState(false);

  // create pgp secret data
  const [pgpValue, setPgpValue] = useState({
    name: "",
    comapnay: localStorage.getItem("company") || "",
    type: "pgp",
    publicKey: "",
    privateKey: "",
    password: "",
  });

  //env options
  const env = [{ name: "DEV" }, { name: "QA" }, { name: "PROD" }];

  useEffect(() => {
    if (localStorage.getItem("userRole")?.includes("ADMIN")) {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    setSftpValue({
      secretName: "",
      company: localStorage.getItem("company") || "",
      host_dev: "",
      port_dev: "",
      username_dev: "",
      password_dev: "",
      host_qa: "",
      port_qa: "",
      username_qa: "",
      password_qa: "",
      host_prod: "",
      port_prod: "",
      username_prod: "",
      password_prod: "",
      type: "sftp",
    });
    setDbValue({
      secretName: "",
      company: localStorage.getItem("company") || "",
      type: "db",
      dbUrl_dev: "",
      username_dev: "",
      password_dev: "",
      dbUrl_qa: "",
      username_qa: "",
      password_qa: "",
      dbUrl_prod: "",
      username_prod: "",
      password_prod: "",
    });

    setPgpValue({
      name: "",
      comapnay: localStorage.getItem("company") || "",
      type: "pgp",
      // secrets: {
      publicKey: "",
      privateKey: "",
      password: "",
      // },
    });
  }, [sftpVisible, dbVisible, pgpVisible]);

  const [sftpValue, setSftpValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    host_dev: "",
    port_dev: "",
    username_dev: "",
    password_dev: "",
    host_qa: "",
    port_qa: "",
    username_qa: "",
    password_qa: "",
    host_prod: "",
    port_prod: "",
    username_prod: "",
    password_prod: "",
    type: "sftp",
  });

  const [dbValue, setDbValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "db",
    dbUrl_dev: "",
    username_dev: "",
    password_dev: "",
    dbUrl_qa: "",
    username_qa: "",
    password_qa: "",
    dbUrl_prod: "",
    username_prod: "",
    password_prod: "",
  });

  const toast = useRef(null);

  // const handleSftpInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setSftpValue({
  //     ...sftpValue,
  //     [name]: value,
  //   });
  //   setSftpError({}); // Clear error message on input change
  // };

  const validateSecretName = (secretName) => {
    const errors = {};

    // Check if secretName starts with an alphabet
    if (!/^[A-Za-z]/.test(secretName)) {
      errors.secretName = "Secret name must start with an alphabet.";
    }

    // Check if secretName contains only alphabets, numbers, and underscores
    if (!/^[A-Za-z0-9_]+$/.test(secretName)) {
      errors.secretName =
        "Secret name can only contain letters, numbers, and underscores.";
    }

    // Check if secretName contains any special characters other than underscores
    if (/[^A-Za-z0-9_]/.test(secretName)) {
      errors.secretName =
        "Special characters are not allowed, except for underscores.";
    }

    return errors;
  };

  const handleSftpInputChange = (e) => {
    const { name, value } = e.target;
    setSftpValue({
      ...sftpValue,
      [name]: value,
    });

    setSftpError({}); // Clear previous error messages

    // Apply only the custom secretName validation
    if (name === "secretName") {
      const validationErrors = validateSecretName(value);
      if (Object.keys(validationErrors).length > 0) {
        setSftpError(validationErrors);
      }
    }
  };

  const handleDbInputChange = (e) => {
    const { name, value } = e.target;
    setDbValue({
      ...dbValue,
      [name]: value,
    });
    setDbError({}); // Clear error message on input change
    if (name === "secretName") {
      const validationErrors = validateSecretName(value);
      if (Object.keys(validationErrors).length > 0) {
        setDbError(validationErrors);
      }
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail, life: 3000 });
  };

  const handlePgpInputChange = (e) => {
    const { name, value } = e.target;
    setPgpValue((prev) => ({ ...prev, [name]: value }));
    setPgpError([]);
  };

  const handlePgpCreate = async (e) => {
    e.preventDefault();
    // console.log(pgpValue, " is the pgp value ");

    const pgpData = {
      name: pgpValue.name,
      company: localStorage.getItem("company") || "",
      type: "pgp",
      secret: {
        publicKey: pgpValue.publicKey,
        privateKey: pgpValue.privateKey,
        passphrase: pgpValue.password,
      },
    };

    // console.log("data prepared for the pgp creation", pgpData);

    const errors = Object.entries(pgpValue)
      .filter(([key, value]) => value === "") // Filter keys where value is an empty string
      .map(([key, value]) => key);

    setPgpError(errors);
    // console.log(errors, " is the error ");
    if (errors.length > 0) {
      showToast("error", "error", "please fill the all details ");
      return;
    }

    // call the create pgp here

    const axiosInstance = await myAxios();
    const response = await axiosInstance
      .post(`/pgp/secret/create`, pgpData)
      .then((resp) => resp.data)
      .catch((e) => e.response.data);
    // console.log(response);

    if (response.status === 201) {
      showToast("success", "Success", "Secrets created successfully!");
      setPgpVisible(false);
    } else if (response.status === 409) {
      showToast("warn", "Conflict", "Secret Already Exist ");
    } else if (response.status === 500) {
      showToast("error", "Error", "Internal Server Error ");
      setPgpVisible(false);
    }

    setPgpValue({
      name: "",
      comapnay: localStorage.getItem("company") || "",
      type: "pgp",
      // secrets: {
      publicKey: "",
      privateKey: "",
      password: "",
      // },
    });
    // setPgpVisible(false);
  };

  // const handleSFTPCreate = () => {
  //   console.log(sftpValue);
  // };
  const handleSFTPCreate = async (e) => {
    e.preventDefault();

    const mandatoryData = {
      name: sftpValue.secretName,
      type: sftpValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        host: sftpValue.host_dev,
        port: sftpValue.port_dev,
        username: sftpValue.username_dev,
        password: sftpValue.password_dev,
      },
    };

    const requestData = {
      name: sftpValue.secretName,
      type: sftpValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        host: sftpValue.host_dev,
        port: sftpValue.port_dev,
        username: sftpValue.username_dev,
        password: sftpValue.password_dev,
      },
      qa: {
        host: sftpValue.host_qa,
        port: sftpValue.port_qa,
        username: sftpValue.username_qa,
        password: sftpValue.password_qa,
      },
      prod: {
        host: sftpValue.host_prod,
        port: sftpValue.port_prod,
        username: sftpValue.username_prod,
        password: sftpValue.password_prod,
      },
    };

    // console.log("Request Data", requestData);

    // const axiosInstance = await myAxios();
    let emptyErrors = checkEmptyFieldForVault(mandatoryData);
    if (requestData.name === "")
      emptyErrors.secretName = `Secret name can't be empty`;
    if (Object.keys(emptyErrors).length !== 0) {
      setSftpError(emptyErrors);
      return;
    }
    try {
      const encryptedData = await handleEncryptedData(requestData);

      const response = await SFTPCreate(encryptedData);
      if (response.status === 201) {
        showToast("success", "Success", "Secrets created successfully!");
      } else if (response.status === 401) {
        navigate("/login");
      } else if (response.status === 409) {
        showToast("warn", "warn", "Secrets name already exist");
        setSftpVisible(true);
        return;
      }

      // Reset state after success
      setSftpVisible(false); // Close dialog
      setSftpValue({
        secretName: "",
        company: localStorage.getItem("company") || "",
        host_dev: "",
        port_dev: "",
        username_dev: "",
        password_dev: "",
        host_qa: "",
        port_qa: "",
        username_qa: "",
        password_qa: "",
        host_prod: "",
        port_prod: "",
        username_prod: "",
        password_prod: "",
        type: "sftp",
      });
    } catch (error) {
      console.log("error : ", error);
      showToast("error", "Error", "Failed to create secrets: " + error.message);
    }
  };

  const handleDBCreate = async (e) => {
    e.preventDefault();

    const mandatoryData = {
      name: dbValue.secretName,
      type: dbValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        dburl: dbValue.dbUrl_dev,
        username: dbValue.username_dev,
        password: dbValue.password_dev,
      },
    };
    const requestData = {
      name: dbValue.secretName,
      type: dbValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        dburl: dbValue.dbUrl_dev,
        username: dbValue.username_dev,
        password: dbValue.password_dev,
      },
      qa: {
        dburl: dbValue.dbUrl_qa,
        username: dbValue.username_qa,
        password: dbValue.password_qa,
      },
      prod: {
        dburl: dbValue.dbUrl_prod,
        username: dbValue.username_prod,
        password: dbValue.password_prod,
      },
    };
    let emptyErrors = checkEmptyFieldForVault(mandatoryData);
    if (requestData.name === "")
      emptyErrors.secretName = `Secret name can't be empty`;
    if (Object.keys(emptyErrors).length !== 0) {
      // console.log("this is the db error", emptyErrors);

      setDbError(emptyErrors);
      return;
    }

    // const axiosInstance = await myAxios();

    try {
      // const response = await axiosInstance
      //   .post("/secrets/create", requestData)
      //   .then((res) => res.data)
      //   .catch((e) => e.response);
      // const encryptedData = await handleEncryptedRequest(requestData);
      const encryptedData = await handleEncryptedData(requestData);

      const response = await DBCreate(encryptedData);
      if (response.status === 201) {
        showToast("success", "Success", "Secrets created successfully!");
      } else if (response.status === 401) {
        navigate("/login");
      } else if (response.status === 409) {
        showToast("warn", "warn", "Secrets name already exist!");
        setDbVisible(true); // Close the dialog on success
      }
      setDbVisible(false); // Close the dialog on success
      // Reset form values after successful submission
      setDbValue({
        secretName: "",
        company: localStorage.getItem("company") || "",
        type: "db",
        dbUrl_dev: "",
        username_dev: "",
        password_dev: "",
        dbUrl_qa: "",
        username_qa: "",
        password_qa: "",
        dbUrl_prod: "",
        username_prod: "",
        password_prod: "",
      });
    } catch (error) {
      console.log(error.message);
      showToast("error", "Error", "Failed to create secrets: " + error.message);
    }
    // console.log(dbValue);
  };

  const sftpFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setSftpVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleSFTPCreate}
        autoFocus
      />
    </div>
  );
  const pgpFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setPgpVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handlePgpCreate}
        autoFocus
      />
    </div>
  );

  const dbFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDbVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleDBCreate}
        autoFocus
      />
    </div>
  );

  const handleTestConnection = async (role1) => {
    // Validate fields before proceeding
    const errors = {};

    // Check for empty fields and add error state
    if (!sftpValue[`host_${role1}`]) errors[`host_${role1}`] = true;
    if (!sftpValue[`port_${role1}`]) errors[`port_${role1}`] = true;
    if (!sftpValue[`username_${role1}`]) errors[`username_${role1}`] = true;
    if (!sftpValue[`password_${role1}`]) errors[`password_${role1}`] = true;

    // Set the errors state to show invalid fields
    setSftpError(errors);

    // If there are any errors, do not proceed further
    if (Object.keys(errors).length > 0) {
      return; // Stop the function if any required field is empty
    }
    const object = {
      server1: sftpValue[`host_${role1}`],
      port1: sftpValue[`port_${role1}`],
      user1: sftpValue[`username_${role1}`],
      pass1: sftpValue[`password_${role1}`],
    };
    setSftpTestVisible(true);
    setLoading(true);
    const axiosInstance = await myAxios();
    const encryptedData = await handleEncryptedData(object);
    let sftp_connect = await axiosInstance
      .post("test/con/sftp/secret", encryptedData)
      .then((res) => res.data)
      .catch((e) => e.response.data);
    setLoading(false);
    setResponseStatus(sftp_connect.status);
    setResponseMessage(sftp_connect.messages);
  };

  const handleDbTestConnection = async (role) => {
    const errors = {};

    // Check for empty fields and add error state
    if (!dbValue[`dbUrl_${role}`]) errors[`dbUrl_${role}`] = true;
    if (!dbValue[`username_${role}`]) errors[`username_${role}`] = true;
    if (!dbValue[`password_${role}`]) errors[`password_${role}`] = true;

    // Set the errors state to show invalid fields
    setDbError(errors);

    // If there are any errors, do not proceed further
    if (Object.keys(errors).length > 0) {
      return; // Stop the function if any required field is empty
    }
    const object = {
      dbUrl: dbValue[`dbUrl_${role}`],
      dbUser: dbValue[`username_${role}`],
      dbPass: dbValue[`password_${role}`],
    };
    setDbTestVisible(true);
    setLoading(true);
    const axiosInstance = await myAxios();
    const encryptedData = await handleEncryptedData(object);
    let db_connect = await axiosInstance
      .post("test/con/db/secret", encryptedData)
      .then((res) => res.data)
      .catch((e) => e.response.data);
    setLoading(false);
    setResponseStatus(db_connect.status);
    setResponseMessage(db_connect.messages);
  };

  return (
    <>
      {!isAdmin && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1>You don't have access to this page please reach to Admin.</h1>
        </div>
      )}

      {isAdmin && (
        <div>
          <Toast ref={toast} />
          <div className="card" style={{ width: "100%" }}>
            <h1 className="Header">Vault Setup </h1>
            <Divider />
          </div>
          <div className="card1">
            <Card title="Create Secret">
              <div style={{ marginTop: "-20px" }}>
                <p>Select the type of secret you want to create</p>
              </div>

              <div className="card flex flex-wrap justify-content-start">
                <div
                  className="SFTPSecrets hover-effect"
                  style={{
                    display: "flex", // Align items in a row (horizontal)
                    alignItems: "center", // Vertically center the items
                    padding: "20px", // Add padding to give some space around the content
                    borderRadius: "8px", // Optional: rounded corners for aesthetics
                    margin: "10px", // Optional: margin around the div
                    width: "100%",
                  }}
                  onClick={() => setSftpVisible(true)}
                >
                  <div>
                    <i
                      className="pi pi-server"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                    <h4>SFTP Secrets</h4>
                    <div style={{ marginTop: "-15px" }}>
                      Securely store and manage SFTP Credentials
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <i className="pi pi-angle-right"></i>
                  </div>
                </div>

                <div
                  className="DBSecrets hover-effect"
                  style={{
                    display: "flex", // Align items in a row (horizontal)
                    alignItems: "center", // Vertically center the items
                    padding: "20px", // Add padding to give some space around the content
                    borderRadius: "8px", // Optional: rounded corners for aesthetics
                    margin: "10px", // Optional: margin around the div
                    width: "100%",
                  }}
                  onClick={() => setDbVisible(true)}
                >
                  <div>
                    <i
                      className="pi pi-database"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                    <h4>Database Secrets</h4>
                    <div style={{ marginTop: "-15px" }}>
                      Securely store and manage Database Credentials
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <i className="pi pi-angle-right"></i>
                  </div>
                </div>

                <div
                  className="pgpSecrets hover-effect"
                  style={{
                    display: "flex", // Align items in a row (horizontal)
                    alignItems: "center", // Vertically center the items
                    padding: "20px", // Add padding to give some space around the content
                    borderRadius: "8px", // Optional: rounded corners for aesthetics
                    margin: "10px", // Optional: margin around the div
                    width: "100%",
                  }}
                  onClick={() => setPgpVisible(true)}
                >
                  <div>
                    <i>
                      <ImKey2 size={30} />
                    </i>
                    {/* <i
                      className="pi pi-database"
                      style={{ fontSize: "30px" }}
                    ></i> */}
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                    <h4>PGP Secrets</h4>
                    <div style={{ marginTop: "-15px" }}>
                      Securely Encrypt and Decrypt Your File
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <i className="pi pi-angle-right"></i>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            <Card
              style={{
                marginTop: "30px",
                background: " rgba(88, 219, 255, 0.2)",
                width: "70%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <i
                  className="pi pi-lock"
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                ></i>
                <h4 style={{ margin: 0 }}>Secure Storage</h4>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "30px",
                }}
              >
                <p>
                  All secrets are encrypted using industry-standard algorithms
                  and stored Securely. Only authorized users with appropriate
                  permissions can access these secrets
                </p>
              </div>
            </Card>
          </div>

          <Dialog
            header="SFTP Secret Details"
            visible={sftpVisible}
            style={{ width: "35vw" }} // Adjust the width as needed
            onHide={() => setSftpVisible(false)}
            footer={sftpFooterContent}
          >
            <div className="flex flex-column align-items-start col-12">
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="sftpName">Name of Secrets</label>
                <InputText
                  id="sftpName"
                  name="secretName"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  invalid={sftpError.secretName} // invalid if custom error exists or field is empty
                />
                {/* Custom Error Popup below the input field */}
                {sftpError.secretName && (
                  <div
                    style={{
                      marginTop: "0.5rem",
                      backgroundColor: "#f8d7da", // Light red background
                      color: "#721c24", // Dark red text color
                      padding: "0.5rem",
                      borderRadius: "4px",
                      fontSize: "0.9em",
                      border: "1px solid #f5c6cb", // Light red border
                      maxWidth: "100%",
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    {sftpError.secretName}
                  </div>
                )}
              </div>

              <div
                className="card"
                style={{
                  marginTop: "1px",
                  width: "100%", // Make the Accordion take up 100% of the parent's width
                  boxSizing: "border-box",
                }}
              >
                <label htmlFor="sftpName">Environment</label>
                <Accordion activeIndex={1} style={{ width: "100%" }}>
                  {" "}
                  {/* Set Accordion width to 100% */}
                  <AccordionTab
                    header="Dev"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <label htmlFor="host_dev">SFTP Host</label>

                    <InputText
                      id="host_dev"
                      name="host_dev"
                      value={sftpValue.host_dev}
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.host_dev}
                    />
                    <label htmlFor="sftpPort_dev">Port</label>

                    <InputText
                      id="sftpPort_dev"
                      name="port_dev"
                      value={sftpValue.port_dev}
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.port_dev}
                    />
                    <label htmlFor="sftpUserName_dev">Username</label>

                    <InputText
                      id="sftpUserName_dev"
                      name="username_dev"
                      value={sftpValue.username_dev}
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.username_dev}
                    />
                    <label htmlFor="sftpPassword_dev">Password</label>

                    <Password
                      className="w-full"
                      id="sftpPassword_dev"
                      name="password_dev"
                      type="password"
                      value={sftpValue.password_dev}
                      toggleMask
                      onChange={handleSftpInputChange}
                      required
                      invalid={sftpError.password_dev}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        label="Verify..."
                        type="test"
                        icon="pi pi-check"
                        severity="primary"
                        onClick={() => handleTestConnection("dev")}
                        className="border-round-lg"
                        style={{
                          marginTop: "4px",
                          padding: "6px 12px", // Adjust padding to decrease height
                          fontSize: "12px", // Adjust font size if needed
                        }}
                      />
                    </div>

                    <ConnectionTestDialog
                      visible={sftptestvisible}
                      setVisible={setSftpTestVisible}
                      loading={loading}
                      responseStatus={responseStatus}
                      responseMessage={responseMessage}
                    />
                  </AccordionTab>
                  <AccordionTab
                    header="QA"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <label htmlFor="host_qa">SFTP Host</label>

                    <InputText
                      id="host_qa"
                      name="host_qa"
                      onChange={handleSftpInputChange}
                      value={sftpValue.host_qa}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.host_qa}
                    />
                    <label htmlFor="sftpPort_qa">Port</label>

                    <InputText
                      id="sftpPort_qa"
                      name="port_qa"
                      onChange={handleSftpInputChange}
                      value={sftpValue.port_qa}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.port_qa}
                    />
                    <label htmlFor="sftpUserName_qa">Username</label>

                    <InputText
                      id="sftpUserName_qa"
                      name="username_qa"
                      value={sftpValue.username_qa}
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.username_qa}
                    />
                    <label htmlFor="sftpPassword_qa">Password</label>

                    <Password
                      className="w-full"
                      id="sftpPassword_qa"
                      name="password_qa"
                      type="password"
                      value={sftpValue.password_qa}
                      toggleMask
                      onChange={handleSftpInputChange}
                      required
                      invalid={sftpError.password_qa}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        label="Verify..."
                        type="test"
                        icon="pi pi-check"
                        severity="primary"
                        onClick={() => handleTestConnection("qa")}
                        className="border-round-lg"
                        style={{
                          marginTop: "4px",
                          padding: "6px 12px", // Adjust padding to decrease height
                          fontSize: "12px", // Adjust font size if needed
                        }}
                      />
                    </div>

                    <ConnectionTestDialog
                      visible={sftptestvisible}
                      setVisible={setSftpTestVisible}
                      loading={loading}
                      responseStatus={responseStatus}
                      responseMessage={responseMessage}
                    />
                  </AccordionTab>
                  <AccordionTab
                    header="Prod"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <label htmlFor="host_prod">SFTP Host</label>

                    <InputText
                      id="host_prod"
                      name="host_prod"
                      value={sftpValue.host_prod}
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.host_prod}
                    />
                    <label htmlFor="sftpPort_prod">Port</label>

                    <InputText
                      id="sftpPort_prod"
                      name="port_prod"
                      onChange={handleSftpInputChange}
                      value={sftpValue.port_prod}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.port_prod}
                    />
                    <label htmlFor="sftpUserName_prod">Username</label>

                    <InputText
                      id="sftpUserName_prod"
                      name="username_prod"
                      onChange={handleSftpInputChange}
                      value={sftpValue.username_prod}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={sftpError.username_prod}
                    />
                    <label htmlFor="sftpPassword_prod">Password</label>

                    <Password
                      className="w-full"
                      id="sftpPassword_prod"
                      name="password_prod"
                      type="password"
                      value={sftpValue.password_prod}
                      toggleMask
                      onChange={handleSftpInputChange}
                      required
                      invalid={sftpError.password_prod}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        label="Verify..."
                        type="test"
                        icon="pi pi-check"
                        severity="primary"
                        onClick={() => handleTestConnection("prod")}
                        className="border-round-lg"
                        style={{
                          marginTop: "4px",
                          padding: "6px 12px", // Adjust padding to decrease height
                          fontSize: "12px", // Adjust font size if needed
                        }}
                      />
                    </div>

                    <ConnectionTestDialog
                      visible={sftptestvisible}
                      setVisible={setSftpTestVisible}
                      loading={loading}
                      responseStatus={responseStatus}
                      responseMessage={responseMessage}
                    />
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
          </Dialog>

          {/* Database Secrets Dialog */}
          <Dialog
            header="Database Secret Details"
            visible={dbVisible}
            style={{ width: "35vw" }} // Adjust the width as needed
            onHide={() => setDbVisible(false)}
            footer={dbFooterContent}
          >
            {/* <div className="flex flex-column align-items-start"> */}
            <div className="flex flex-column align-items-start col-12">
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="dbName">Secret Name</label>
                <InputText
                  id="dbName"
                  name="secretName"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  invalid={dbError.secretName}
                />
                {dbError.secretName && (
                  <div
                    style={{
                      marginTop: "0.5rem",
                      backgroundColor: "#f8d7da", // Light red background
                      color: "#721c24", // Dark red text color
                      padding: "0.5rem",
                      borderRadius: "4px",
                      fontSize: "0.9em",
                      border: "1px solid #f5c6cb", // Light red border
                      maxWidth: "100%",
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    {dbError.secretName}
                  </div>
                )}
              </div>

              <div
                className="card"
                style={{
                  marginTop: "1px",
                  width: "100%", // Make the Accordion take up 100% of the parent's width
                  boxSizing: "border-box",
                }}
              >
                <label htmlFor="sftpName">Environment</label>
                <Accordion activeIndex={1} style={{ width: "100%" }}>
                  {" "}
                  {/* Set Accordion width to 100% */}
                  <AccordionTab
                    header="Dev"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <label htmlFor="dbUrl_dev">Database URL</label>
                    <InputText
                      id="dbUrl_dev"
                      name="dbUrl_dev"
                      onChange={handleDbInputChange}
                      value={dbValue.dbUrl_dev}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={dbError.dburl_dev || dbError.dbUrl_dev}
                    />
                    <label htmlFor="dbUserName_dev">Username</label>

                    <InputText
                      id="dbUserName_dev"
                      name="username_dev"
                      onChange={handleDbInputChange}
                      value={dbValue.username_dev}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={dbError.username_dev}
                    />
                    <label htmlFor="dbPassword_dev">Password</label>

                    <Password
                      className="w-full"
                      id="dbPassword_dev"
                      name="password_dev"
                      type="password"
                      toggleMask
                      onChange={handleDbInputChange}
                      value={dbValue.password_dev}
                      required
                      invalid={dbError.password_dev}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        label="Verify..."
                        type="test"
                        icon="pi pi-check"
                        severity="primary"
                        onClick={() => handleDbTestConnection("dev")}
                        className="border-round-lg"
                        style={{
                          marginTop: "4px",
                          padding: "6px 12px", // Adjust padding to decrease height
                          fontSize: "12px", // Adjust font size if needed
                        }}
                      />
                    </div>

                    <ConnectionTestDialog
                      visible={dbtestvisible}
                      setVisible={setDbTestVisible}
                      loading={loading}
                      responseStatus={responseStatus}
                      responseMessage={responseMessage}
                    />
                  </AccordionTab>
                  <AccordionTab
                    header="QA"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <label htmlFor="dbUrl_qa">Database URL</label>

                    <InputText
                      id="dbUrl_qa"
                      name="dbUrl_qa"
                      onChange={handleDbInputChange}
                      value={dbValue.dbUrl_qa}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={dbError.dburl_qa || dbError.dbUrl_qa}
                    />
                    <label htmlFor="dbUserName_qa">Username</label>

                    <InputText
                      id="dbUserName_qa"
                      name="username_qa"
                      onChange={handleDbInputChange}
                      value={dbValue.username_qa}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={dbError.username_qa}
                    />
                    <label htmlFor="dbPassword_qa">Password</label>

                    <Password
                      className="w-full"
                      id="dbPassword_qa"
                      name="password_qa"
                      type="password_qa"
                      toggleMask
                      onChange={handleDbInputChange}
                      value={dbValue.password_qa}
                      required
                      invalid={dbError.password_qa}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        label="Verify..."
                        type="test"
                        icon="pi pi-check"
                        severity="primary"
                        onClick={() => handleDbTestConnection("qa")}
                        className="border-round-lg"
                        style={{
                          marginTop: "4px",
                          padding: "6px 12px", // Adjust padding to decrease height
                          fontSize: "12px", // Adjust font size if needed
                        }}
                      />
                    </div>

                    <ConnectionTestDialog
                      visible={dbtestvisible}
                      setVisible={setDbTestVisible}
                      loading={loading}
                      responseStatus={responseStatus}
                      responseMessage={responseMessage}
                    />
                  </AccordionTab>
                  <AccordionTab
                    header="Prod"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <label htmlFor="dbUrl_prod">Database URL</label>

                    <InputText
                      id="dbUrl_prod"
                      name="dbUrl_prod"
                      onChange={handleDbInputChange}
                      value={dbValue.dbUrl_prod}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={dbError.dburl_prod || dbError.dbUrl_prod}
                    />
                    <label htmlFor="dbUserName_prod">Username</label>

                    <InputText
                      id="dbUserName_prod"
                      name="username_prod"
                      onChange={handleDbInputChange}
                      value={dbValue.username_prod}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      invalid={dbError.username_prod}
                    />
                    <label htmlFor="dbPassword_prod">Password</label>

                    <Password
                      className="w-full"
                      id="dbPassword_prod"
                      name="password_prod"
                      type="password_prod"
                      toggleMask
                      onChange={handleDbInputChange}
                      value={dbValue.password_prod}
                      required
                      invalid={dbError.password_prod}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        label="Verify..."
                        type="test"
                        icon="pi pi-check"
                        severity="primary"
                        onClick={() => handleDbTestConnection("prod")}
                        className="border-round-lg"
                        style={{
                          marginTop: "4px",
                          padding: "6px 12px", // Adjust padding to decrease height
                          fontSize: "12px", // Adjust font size if needed
                        }}
                      />
                    </div>

                    <ConnectionTestDialog
                      visible={dbtestvisible}
                      setVisible={setDbTestVisible}
                      loading={loading}
                      responseStatus={responseStatus}
                      responseMessage={responseMessage}
                    />
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
          </Dialog>

          {/* Pgp Secret Dialog */}

          <Dialog
            header="PGP Secret Details"
            visible={pgpVisible}
            style={{ width: "60vw" }} // Adjust the width as needed
            onHide={() => setPgpVisible(false)}
            footer={pgpFooterContent}
          >
            {/* <div className="flex flex-column align-items-start"> */}
            <div className="flex flex-column align-items-start col-12">
              <div
                className="p-field col-12"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                {/* Column 1 for pgp secrets */}
                <div style={{ flex: "1" }} className="col-4">
                  <label style={{ visibility: "hidden" }}>Secret Name </label>
                  <div className="my-2 ">
                    <FloatLabel>
                      <label htmlFor="pgpname">Secret Name</label>
                      <InputText
                        id="pgpname"
                        className="w-full"
                        name="name"
                        placeholder="Enter Pgp Secret Name "
                        value={pgpValue.name}
                        onChange={handlePgpInputChange}
                        required
                        invalid={pgpError.includes("name")}
                      />
                    </FloatLabel>
                  </div>

                  <div className="my-4  ">
                    <FloatLabel>
                      <label htmlFor="pgpPublickey">Public Key :</label>
                      <InputTextarea
                        value={pgpValue.publicKey}
                        name="publicKey"
                        id="pgpPublickey"
                        cols={40}
                        rows={15}
                        placeholder="Enter pgp public key here"
                        onChange={handlePgpInputChange}
                        required
                        invalid={pgpError.includes("publicKey")}
                      />
                    </FloatLabel>
                  </div>
                </div>

                {/* Column 2 for the pgp secret */}
                <div style={{ flex: "1" }} className="col-4">
                  <div className="my-2">
                    <label htmlFor="pgpPassword">Password</label>
                    {/* <FloatLabel> */}
                    <Password
                      className="w-full"
                      id="pgpPassword"
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      toggleMask
                      onChange={handlePgpInputChange}
                      required
                      invalid={pgpError.includes("password")}
                    />
                    {/* </FloatLabel> */}
                  </div>
                  <div className="my-4  ">
                    <FloatLabel>
                      <label htmlFor="pgpPrivatekey">Private Key :</label>
                      <InputTextarea
                        value={pgpValue.privateKey}
                        name="privateKey"
                        id="pgpPrivateckey"
                        cols={40}
                        rows={15}
                        placeholder="Enter pgp private key here"
                        onChange={handlePgpInputChange}
                        required
                        invalid={pgpError.includes("privateKey")}
                      />
                    </FloatLabel>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
