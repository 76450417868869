import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button"; // For action buttons (Edit/Delete)
import Breadcrumbs from "../components/Breadcrumb";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Password } from "primereact/password";
import { useUserContext } from "../components/contextAPIs/UserContext";
import { handleEncryptedData } from "../Encrypt API/ModifiedEncrypt";
import {
  checkEmptyField,
  checkEmptyFieldForVault,
} from "../common/handleEmptyField";
import {
  deleteSecret,
  getAllSecretsSftpAndDB,
  updateDBSecret,
  updateSecret,
} from "../API Services/VaultSecrets/ViewSecretsService";
import DataTableSkeleton from "./skeleton/DataTableSkeleton";
import ConnectionTestDialog from "../Dialog/ConnectionTestDialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { createAxiosInstance as myAxios } from "../services/Helper";

export default function ViewSecret() {
  //States for test connection.
  const [testloading, setTestLoading] = useState(true);
  const [sftptestvisible, setSftpTestVisible] = useState(false);
  const [dbtestvisible, setDbTestVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  const [secrets, setSecrets] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    host: { value: null, matchMode: FilterMatchMode.CONTAINS },
    port: { value: null, matchMode: FilterMatchMode.EQUALS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [sftpList, setSftpList] = useState([]);
  const [dbList, setDbList] = useState([]);
  const [selectedViewSecrets, setSelectedViewSecrets] =
    useState("SFTP secrets");
  const toast = useRef(null);
  const { getRole } = useUserContext();
  const role = getRole();

  const [sftpVisible, setSftpVisible] = useState(false);
  const [sftpValue, setSftpValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "sftp",
    host_dev: "",
    port_dev: "",
    username_dev: "",
    password_dev: "",
    host_qa: "",
    port_qa: "",
    username_qa: "",
    password_qa: "",
    host_prod: "",
    port_prod: "",
    username_prod: "",
    password_prod: "",
  });
  const [sftpError, setSftpError] = useState({});
  const [dbValue, setDbValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "db",
    dbUrl_dev: "",
    username_dev: "",
    password_dev: "",
    dbUrl_qa: "",
    username_qa: "",
    password_qa: "",
    dbUrl_prod: "",
    username_prod: "",
    password_prod: "",
  });
  const [dbError, setDbError] = useState({});
  const [dbVisible, setDbVisible] = useState(false);
  const handleSftpInputChange = (e) => {
    const { name, value } = e.target;
    setSftpValue({
      ...sftpValue,
      [name]: value,
    });
    setSftpError({});
  };
  const handleDbInputChange = (e) => {
    const { name, value } = e.target;
    setDbValue({
      ...dbValue,
      [name]: value,
      name,
    });
    setDbError({});
  };
  const saveHandler = async () => {
    const mandatoryData = {
      name: sftpValue.secretName,
      type: sftpValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        host: sftpValue.host_dev,
        port: sftpValue.port_dev,
        username: sftpValue.username_dev,
        password: sftpValue.password_dev,
      },
    };
    const requestData = {
      name: sftpValue.secretName,
      type: "sftp",
      company: localStorage.getItem("company") || "",
      dev: {
        host: sftpValue.host_dev,
        port: sftpValue.port_dev,
        username: sftpValue.username_dev,
        password: sftpValue.password_dev,
      },
      qa: {
        host: sftpValue.host_qa,
        port: sftpValue.port_qa,
        username: sftpValue.username_qa,
        password: sftpValue.password_qa,
      },
      prod: {
        host: sftpValue.host_prod,
        port: sftpValue.port_prod,
        username: sftpValue.username_prod,
        password: sftpValue.password_prod,
      },
    };

    try {
      let emptyErrors = checkEmptyFieldForVault(mandatoryData);
      if (requestData.name === "")
        emptyErrors.secretName = `name can't be empty`;
      if (Object.keys(emptyErrors).length !== 0) {
        setSftpError(emptyErrors);
        return;
      }
      const encryptedData = await handleEncryptedData(requestData);
      const response = await updateSecret(encryptedData);
      // console.log(response, " update response ");

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "SFTP details updated successfully.",
          life: 3000,
        });

        setSecrets((secrets) =>
          secrets.map((secret) =>
            secret.name === requestData.name ? requestData : secret
          )
        );
        setSftpList((secrets) =>
          secrets.map((secret) =>
            secret.name === requestData.name ? requestData : secret
          )
        );
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : "Something went wrong!";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    }
    setSftpVisible(false); // Close the dialog after saving
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const viewSecrets = [{ name: "SFTP secrets" }, { name: "Database secrets" }];
  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ flex: 1 }} />
        <h2 style={{ textAlign: "center", flex: 2 }}>{selectedViewSecrets}</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Dropdown
            value={viewSecrets.find((e) => e.name === selectedViewSecrets)}
            onChange={(e) => {
              // console.log(e.value, "value of secrets ");
              setSelectedViewSecrets(e.value.name);
              if (e.value.name === "Database secrets") {
                setSecrets(dbList); // Show DB secrets
              } else {
                setSecrets(sftpList); // Show SFTP secrets
              }
            }}
            options={viewSecrets}
            optionLabel="name"
            placeholder="Select Secret"
            className="p-inputtext-m"
            style={{ width: "13rem", marginRight: "1rem" }}
          />
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
              className="p-inputtext-m"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const saveDbHandler = async () => {
    const mandatoryData = {
      name: dbValue.secretName,
      type: dbValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        dburl: dbValue.dbUrl_dev,
        username: dbValue.username_dev,
        password: dbValue.password_dev,
      },
    };
    const requestData = {
      name: dbValue.secretName,
      type: "db",
      company: localStorage.getItem("company") || "",

      dev: {
        dburl: dbValue.dbUrl_dev,
        username: dbValue.username_dev,
        password: dbValue.password_dev,
      },
      qa: {
        dburl: dbValue.dbUrl_qa,
        username: dbValue.username_qa,
        password: dbValue.password_qa,
      },
      prod: {
        dburl: dbValue.dbUrl_prod,
        username: dbValue.username_prod,
        password: dbValue.password_prod,
      },
    };

    try {
      let emptyErrors = checkEmptyFieldForVault(mandatoryData);
      if (requestData.name === "")
        emptyErrors.secretName = `name can't be empty`;
      if (Object.keys(emptyErrors).length !== 0) {
        // console.log("this is the db error", emptyErrors);

        setDbError(emptyErrors);
        return;
      }
      const encryptedData = await handleEncryptedData(requestData);
      const response = await updateDBSecret(encryptedData);
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Database details updated successfully.",
          life: 3000,
        });

        setSecrets((secrets) =>
          secrets.map((secret) =>
            secret.name === requestData.name ? requestData : secret
          )
        );

        setDbList((secrets) =>
          secrets.map((secret) =>
            secret.name === requestData.name ? requestData : secret
          )
        );
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : "Something went wrong!";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    }
    setDbVisible(false);
  };

  const sftpFooterContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setSftpVisible(false)}
      />
      <Button label="Update" icon="pi pi-check" onClick={() => saveHandler()} />
    </div>
  );

  const dbFooterContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setDbVisible(false)}
      />
      <Button
        label="Update"
        icon="pi pi-check"
        onClick={() => saveDbHandler()}
      />
    </div>
  );

  // fetch sftp and db request .
  async function loadSecretsWithRetry(retries = 3, delay = 2000) {
    try {
      const responsedb = await getAllSecretsSftpAndDB("db");
      return responsedb;
    } catch (error) {
      if (retries > 0) {
        console.warn(`Retrying request... Attempts left: ${retries}`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return loadSecretsWithRetry(retries - 1, delay);
      } else {
        throw error;
      }
    }
  }
  async function loadSecretsWithRetrySFTP(retries = 3, delay = 2000) {
    try {
      const response = await getAllSecretsSftpAndDB("sftp");
      return response;
    } catch (error) {
      if (retries > 0) {
        console.warn(`Retrying request... Attempts left: ${retries}`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return loadSecretsWithRetry(retries - 1, delay);
      } else {
        throw error;
      }
    }
  }

  useEffect(() => {
    // setLoading(true);
    async function loadSftpSecrets() {
      try {
        const response = await loadSecretsWithRetrySFTP();
        if (response.status === 200) {
          setSftpList(response.data);
          if (selectedViewSecrets === "SFTP secrets") {
            setSecrets(response.data);
          }
        } else {
          setSftpList([]);
        }
      } catch (error) {
        console.log(error, " faild to load after retry ");
        setSftpList([]);
      } finally {
        setLoading(false);
      }
    }
    loadSftpSecrets();
  }, []);

  useEffect(() => {
    // setLoading(true);

    async function loadSecrets() {
      try {
        const responsedb = await loadSecretsWithRetry();

        if (responsedb.status === 200) {
          setDbList(responsedb.data);
          if (selectedViewSecrets === "Database secrets") {
            setSecrets(responsedb.data);
          }
        } else {
          setDbList([]);
        }
      } catch (error) {
        console.error("Failed to load secrets after retries:", error);
        setDbList([]); // Clear dbList in case of error
      } finally {
        // setLoading(false);
      }
    }

    loadSecrets();
  }, []);

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2 font-bold line-height-3">
          {data.name}
        </span>
      </React.Fragment>
    );
  };
  const headerDevBodyTemplate = (data) => {
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.username}
            </p>
            <Password
              className={`vertical-align-middle ml-2  line-height-3`}
              value={data.dev.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.username}
            </p>
            <Password
              className={`vertical-align-middle ml-2  line-height-3`}
              value={data.dev.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };
  const headerQABodyTemplate = (data) => {
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.username}
            </p>
            <Password
              className={`vertical-align-middle ml-2  line-height-3 `}
              style={{ display: data.qa.password ? "block" : "none" }}
              value={data.qa.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.username}
            </p>
            <Password
              className={`vertical-align-middle ml-2  line-height-3`}
              style={{ display: data.qa.password ? "block" : "none" }}
              value={data.qa.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };
  const headerProdBodyTemplate = (data) => {
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.username}
            </p>
            <Password
              className={`vertical-align-middle ml-2  line-height-3`}
              style={{ display: data.prod.password ? "block" : "none" }}
              value={data.prod.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.username}
            </p>
            <Password
              className={`vertical-align-middle ml-2  line-height-3`}
              style={{ display: data.prod.password ? "block" : "none" }}
              value={data.prod.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };

  const editHandler = (rowData) => {
    // console.log(rowData);
    if (selectedViewSecrets === "SFTP secrets") {
      //         // If "SFTP secrets" is selected, open the SFTP dialog and populate the values
      setSftpValue({
        secretName: rowData.name,
        company: localStorage.getItem("company") || "",
        type: "sftp",

        host_dev: rowData.dev.host,
        port_dev: rowData.dev.port,
        username_dev: rowData.dev.username,
        password_dev: rowData.dev.password,

        host_qa: rowData.qa.host,
        port_qa: rowData.qa.port,
        username_qa: rowData.qa.username,
        password_qa: rowData.qa.password,

        host_prod: rowData.prod.host,
        port_prod: rowData.prod.port,
        username_prod: rowData.prod.username,
        password_prod: rowData.prod.password,
      });
      setSftpVisible(true); // Open SFTP dialog
    } else if (selectedViewSecrets === "Database secrets") {
      // If "Database secrets" is selected, open the Database dialog and populate the values
      // console.log(rowData);
      setDbValue({
        secretName: rowData.name,
        company: localStorage.getItem("company") || "",
        type: "db",

        dbUrl_dev: rowData.dev.dburl,
        username_dev: rowData.dev.username,
        password_dev: rowData.dev.password,

        dbUrl_qa: rowData.qa.dburl,
        username_qa: rowData.qa.username,
        password_qa: rowData.qa.password,

        dbUrl_prod: rowData.prod.dburl,
        username_prod: rowData.prod.username,
        password_prod: rowData.prod.password,
      });

      setDbVisible(true); // Open Database dialog
    }
  };

  const accept = async (name) => {
    // remove secrets by name
    try {
      const response = await deleteSecret(name);
      if (response.status === 200) {
        // console.log(response);
        toast.current.show({
          severity: "success",
          summary: "Deleted",
          detail: `Record with ID ${name} has been deleted.`,
          life: 3000,
        });

        setSecrets((secrets) =>
          secrets.filter((secret) => secret.name != name)
        );

        if (selectedViewSecrets === "SFTP secrets") {
          setSftpList((secrets) =>
            secrets.filter((secret) => secret.name != name)
          );
        } else {
          setDbList((secrets) =>
            secrets.filter((secret) => secret.name != name)
          );
        }

        //
      } else {
        toast.current.show({
          severity: "error",
          summary: "Delete Failed",
          detail: `Failed to delete record with ID ${name}.`,
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error occurred: ${error.message}`,
        life: 3000,
      });
    }
  };

  // Function to handle the reject action
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Cancelled",
      detail: "Delete operation cancelled",
      life: 3000,
    });
  };

  // Function to show the confirmation dialog
  const confirmDelete = (name) => {
    confirmDialog({
      message: `Are you sure you want to delete the record with ID ${name}?`,
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(name), // Call accept with the id to delete
      reject, // Call reject to cancel
    });
  };

  const actionColumn = role === "ADMIN" && (
    <Column
      style={{ width: "10%" }}
      key="actions"
      header="Actions"
      body={(rowData) => (
        <div className="flex flex-row align-items-center justify-content-center gap-2">
          <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-pencil"
            rounded
            text
            severity="secondary"
            onClick={() => editHandler(rowData)} // Pass rowData to editHandler
            aria-label="Edit"
          />
          <Button
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            aria-label="Delete"
            onClick={() => confirmDelete(rowData.name)} // Handle row deletion
          />
        </div>
      )}
    />
  );

  const [expandedRows, setExpandedRows] = useState([]);

  const header = renderHeader();

  const handleTestConnection = async (role1) => {
    // Validate fields before proceeding
    const errors = {};

    // Check for empty fields and add error state
    if (!sftpValue[`host_${role1}`]) errors[`host_${role1}`] = true;
    if (!sftpValue[`port_${role1}`]) errors[`port_${role1}`] = true;
    if (!sftpValue[`username_${role1}`]) errors[`username_${role1}`] = true;
    if (!sftpValue[`password_${role1}`]) errors[`password_${role1}`] = true;

    // Set the errors state to show invalid fields
    setSftpError(errors);

    // If there are any errors, do not proceed further
    if (Object.keys(errors).length > 0) {
      return; // Stop the function if any required field is empty
    }
    const object = {
      server1: sftpValue[`host_${role1}`],
      port1: sftpValue[`port_${role1}`],
      user1: sftpValue[`username_${role1}`],
      pass1: sftpValue[`password_${role1}`],
    };
    setSftpTestVisible(true);
    setTestLoading(true);
    const axiosInstance = await myAxios();
    const encryptedData = await handleEncryptedData(object);
    let sftp_connect = await axiosInstance
      .post("test/con/sftp/secret", encryptedData)
      .then((res) => res.data)
      .catch((e) => e.response.data);
    setTestLoading(false);
    setResponseStatus(sftp_connect.status);
    setResponseMessage(sftp_connect.messages);
  };

  const handleDbTestConnection = async (role) => {
    const errors = {};

    // Check for empty fields and add error state
    if (!dbValue[`dbUrl_${role}`]) errors[`dbUrl_${role}`] = true;
    if (!dbValue[`username_${role}`]) errors[`username_${role}`] = true;
    if (!dbValue[`password_${role}`]) errors[`password_${role}`] = true;

    // Set the errors state to show invalid fields
    setDbError(errors);

    // If there are any errors, do not proceed further
    if (Object.keys(errors).length > 0) {
      return; // Stop the function if any required field is empty
    }
    const object = {
      dbUrl: dbValue[`dbUrl_${role}`],
      dbUser: dbValue[`username_${role}`],
      dbPass: dbValue[`password_${role}`],
    };
    setDbTestVisible(true);
    setTestLoading(true);
    const axiosInstance = await myAxios();
    const encryptedData = await handleEncryptedData(object);
    let db_connect = await axiosInstance
      .post("test/con/db/secret", encryptedData)
      .then((res) => res.data)
      .catch((e) => e.response.data);
    setTestLoading(false);
    setResponseStatus(db_connect.status);
    setResponseMessage(db_connect.messages);
  };

  return (
    <div>
      <Breadcrumbs />
      {loading ? (
        <DataTableSkeleton />
      ) : (
        <>
          <Toast ref={toast} />
          <ConfirmDialog />
          <div className="card">
            <DataTable
              value={secrets}
              rowGroupMode="subheader"
              header={header}
              groupRowsBy="name"
              sortMode="single"
              sortField="name"
              filters={filters}
              filterDisplay="row"
              rowGroupHeaderTemplate={headerTemplate}
              sortOrder={1}
              expandableRowGroups
              expandedRows={expandedRows}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                field="name"
                header="Name"
                style={{ width: "22.5%" }}
                filter
                filterPlaceholder="search by name"
              ></Column>

              {/* <Column field="type" style={{ width: "16%" }} header="Type"></Column> */}
              <Column
                field="dev"
                header="Dev"
                style={{ width: "22.5%" }}
                body={headerDevBodyTemplate}
              ></Column>
              <Column
                field="qa"
                header="QA"
                style={{ width: "22.5%" }}
                body={headerQABodyTemplate}
              ></Column>
              <Column
                field="prod"
                header="PROD"
                style={{ width: "22.5%" }}
                body={headerProdBodyTemplate}
              ></Column>
              {/* Directly render actionColumn as a JSX element */}
              {actionColumn}
            </DataTable>
          </div>
          {selectedViewSecrets === "SFTP secrets" && (
            <Dialog
              header="SFTP Secret Details"
              visible={sftpVisible}
              style={{ width: "35vw" }} // Adjust the width as needed
              onHide={() => setSftpVisible(false)}
              footer={sftpFooterContent}
            >
              <div className="flex flex-column align-items-start col-12">
                <div
                  className="p-field"
                  style={{ width: "100%", marginBottom: "1rem" }}
                >
                  <label htmlFor="sftpName">Name of Secrets</label>
                  <InputText
                    id="sftpName"
                    name="secretName"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    value={sftpValue.secretName}
                    disabled
                  />
                </div>

                <div
                  className="card"
                  style={{
                    marginTop: "1px",
                    width: "100%", // Make the Accordion take up 100% of the parent's width
                    boxSizing: "border-box",
                  }}
                >
                  <label htmlFor="sftpName">Environment</label>
                  <Accordion activeIndex={1} style={{ width: "100%" }}>
                    {" "}
                    {/* Set Accordion width to 100% */}
                    <AccordionTab
                      header="Dev"
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label htmlFor="host_dev">SFTP Host</label>

                      <InputText
                        id="host_dev"
                        name="host_dev"
                        value={sftpValue.host_dev}
                        onChange={handleSftpInputChange}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.host_dev}
                      />
                      <label htmlFor="sftpPort_dev">Port</label>

                      <InputText
                        id="sftpPort_dev"
                        name="port_dev"
                        value={sftpValue.port_dev}
                        onChange={handleSftpInputChange}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.port_dev}
                      />
                      <label htmlFor="sftpUserName_dev">Username</label>

                      <InputText
                        id="sftpUserName_dev"
                        name="username_dev"
                        value={sftpValue.username_dev}
                        onChange={handleSftpInputChange}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.username_dev}
                      />
                      <label htmlFor="sftpPassword_dev">Password</label>

                      <Password
                        className="w-full"
                        id="sftpPassword_dev"
                        name="password_dev"
                        type="password"
                        value={sftpValue.password_dev}
                        toggleMask
                        onChange={handleSftpInputChange}
                        required
                        invalid={sftpError.password_dev}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          label="Verify..."
                          type="test"
                          icon="pi pi-check"
                          severity="primary"
                          onClick={() => handleTestConnection("dev")}
                          className="border-round-lg"
                          style={{
                            marginTop: "4px",
                            padding: "6px 12px", // Adjust padding to decrease height
                            fontSize: "12px", // Adjust font size if needed
                          }}
                        />
                      </div>

                      <ConnectionTestDialog
                        visible={sftptestvisible}
                        setVisible={setSftpTestVisible}
                        loading={testloading}
                        responseStatus={responseStatus}
                        responseMessage={responseMessage}
                      />
                    </AccordionTab>
                    <AccordionTab
                      header="QA"
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label htmlFor="host_qa">SFTP Host</label>

                      <InputText
                        id="host_qa"
                        name="host_qa"
                        onChange={handleSftpInputChange}
                        value={sftpValue.host_qa}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.host_qa}
                      />
                      <label htmlFor="sftpPort_qa">Port</label>

                      <InputText
                        id="sftpPort_qa"
                        name="port_qa"
                        onChange={handleSftpInputChange}
                        value={sftpValue.port_qa}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.port_qa}
                      />
                      <label htmlFor="sftpUserName_qa">Username</label>

                      <InputText
                        id="sftpUserName_qa"
                        name="username_qa"
                        value={sftpValue.username_qa}
                        onChange={handleSftpInputChange}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.username_qa}
                      />
                      <label htmlFor="sftpPassword_qa">Password</label>

                      <Password
                        className="w-full"
                        id="sftpPassword_qa"
                        name="password_qa"
                        type="password"
                        value={sftpValue.password_qa}
                        toggleMask
                        onChange={handleSftpInputChange}
                        required
                        invalid={sftpError.password_qa}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          label="Verify..."
                          type="test"
                          icon="pi pi-check"
                          severity="primary"
                          onClick={() => handleTestConnection("qa")}
                          className="border-round-lg"
                          style={{
                            marginTop: "4px",
                            padding: "6px 12px", // Adjust padding to decrease height
                            fontSize: "12px", // Adjust font size if needed
                          }}
                        />
                      </div>

                      <ConnectionTestDialog
                        visible={sftptestvisible}
                        setVisible={setSftpTestVisible}
                        loading={testloading}
                        responseStatus={responseStatus}
                        responseMessage={responseMessage}
                      />
                    </AccordionTab>
                    <AccordionTab
                      header="Prod"
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label htmlFor="host_prod">SFTP Host</label>

                      <InputText
                        id="host_prod"
                        name="host_prod"
                        value={sftpValue.host_prod}
                        onChange={handleSftpInputChange}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.host_prod}
                      />
                      <label htmlFor="sftpPort_prod">Port</label>

                      <InputText
                        id="sftpPort_prod"
                        name="port_prod"
                        onChange={handleSftpInputChange}
                        value={sftpValue.port_prod}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.port_prod}
                      />
                      <label htmlFor="sftpUserName_prod">Username</label>

                      <InputText
                        id="sftpUserName_prod"
                        name="username_prod"
                        onChange={handleSftpInputChange}
                        value={sftpValue.username_prod}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={sftpError.username_prod}
                      />
                      <label htmlFor="sftpPassword_prod">Password</label>

                      <Password
                        className="w-full"
                        id="sftpPassword_prod"
                        name="password_prod"
                        type="password"
                        value={sftpValue.password_prod}
                        toggleMask
                        onChange={handleSftpInputChange}
                        required
                        invalid={sftpError.password_prod}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          label="Verify..."
                          type="test"
                          icon="pi pi-check"
                          severity="primary"
                          onClick={() => handleTestConnection("prod")}
                          className="border-round-lg"
                          style={{
                            marginTop: "4px",
                            padding: "6px 12px", // Adjust padding to decrease height
                            fontSize: "12px", // Adjust font size if needed
                          }}
                        />
                      </div>

                      <ConnectionTestDialog
                        visible={sftptestvisible}
                        setVisible={setSftpTestVisible}
                        loading={testloading}
                        responseStatus={responseStatus}
                        responseMessage={responseMessage}
                      />
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </Dialog>
          )}
          {selectedViewSecrets === "Database secrets" && (
            <Dialog
              header="Database Secret Details"
              visible={dbVisible}
              style={{ width: "35vw" }} // Adjust the width as needed
              onHide={() => setDbVisible(false)}
              footer={dbFooterContent}
            >
              {/* <div className="flex flex-column align-items-start"> */}
              <div className="flex flex-column align-items-start col-12">
                <div
                  className="p-field"
                  style={{ width: "100%", marginBottom: "1rem" }}
                >
                  <label htmlFor="dbName">Secret Name</label>

                  <InputText
                    id="dbName"
                    name="secretName"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    value={dbValue.secretName}
                    disabled
                  />
                </div>

                <div
                  className="card"
                  style={{
                    marginTop: "1px",
                    width: "100%", // Make the Accordion take up 100% of the parent's width
                    boxSizing: "border-box",
                  }}
                >
                  <label htmlFor="sftpName">Environment</label>
                  <Accordion activeIndex={1} style={{ width: "100%" }}>
                    {" "}
                    {/* Set Accordion width to 100% */}
                    <AccordionTab
                      header="Dev"
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label htmlFor="dbUrl_dev">Database URL</label>
                      <InputText
                        id="dbUrl_dev"
                        name="dbUrl_dev"
                        onChange={handleDbInputChange}
                        value={dbValue.dbUrl_dev}
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={dbError.dbUrl_dev || dbError.dburl_dev}
                      />
                      <label htmlFor="dbUserName_dev">Username</label>

                      <InputText
                        id="dbUserName_dev"
                        name="username_dev"
                        onChange={handleDbInputChange}
                        value={dbValue.username_dev}
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={dbError.username_dev}
                      />
                      <label htmlFor="dbPassword_dev">Password</label>

                      <Password
                        className="w-full"
                        id="dbPassword_dev"
                        name="password_dev"
                        type="password"
                        toggleMask
                        onChange={handleDbInputChange}
                        value={dbValue.password_dev}
                        required
                        invalid={dbError.password_dev}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          label="Verify..."
                          type="test"
                          icon="pi pi-check"
                          severity="primary"
                          onClick={() => handleDbTestConnection("dev")}
                          className="border-round-lg"
                          style={{
                            marginTop: "4px",
                            padding: "6px 12px", // Adjust padding to decrease height
                            fontSize: "12px", // Adjust font size if needed
                          }}
                        />
                      </div>

                      <ConnectionTestDialog
                        visible={dbtestvisible}
                        setVisible={setDbTestVisible}
                        loading={testloading}
                        responseStatus={responseStatus}
                        responseMessage={responseMessage}
                      />
                    </AccordionTab>
                    <AccordionTab
                      header="QA"
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label htmlFor="dbUrl_qa">Database URL</label>

                      <InputText
                        id="dbUrl_qa"
                        name="dbUrl_qa"
                        onChange={handleDbInputChange}
                        value={dbValue.dbUrl_qa}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={dbError.dbUrl_qa || dbError.dburl_qa}
                      />
                      <label htmlFor="dbUserName_qa">Username</label>

                      <InputText
                        id="dbUserName_qa"
                        name="username_qa"
                        onChange={handleDbInputChange}
                        value={dbValue.username_qa}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={dbError.username_qa}
                      />
                      <label htmlFor="dbPassword_qa">Password</label>

                      <Password
                        className="w-full"
                        id="dbPassword_qa"
                        name="password_qa"
                        type="password_qa"
                        toggleMask
                        onChange={handleDbInputChange}
                        value={dbValue.password_qa}
                        required
                        invalid={dbError.password_qa}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          label="Verify..."
                          type="test"
                          icon="pi pi-check"
                          severity="primary"
                          onClick={() => handleDbTestConnection("qa")}
                          className="border-round-lg"
                          style={{
                            marginTop: "4px",
                            padding: "6px 12px", // Adjust padding to decrease height
                            fontSize: "12px", // Adjust font size if needed
                          }}
                        />
                      </div>

                      <ConnectionTestDialog
                        visible={dbtestvisible}
                        setVisible={setDbTestVisible}
                        loading={testloading}
                        responseStatus={responseStatus}
                        responseMessage={responseMessage}
                      />
                    </AccordionTab>
                    <AccordionTab
                      header="Prod"
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label htmlFor="dbUrl_prod">Database URL</label>

                      <InputText
                        id="dbUrl_prod"
                        name="dbUrl_prod"
                        onChange={handleDbInputChange}
                        value={dbValue.dbUrl_prod}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={dbError.dbUrl_prod || dbError.dburl_prod}
                      />
                      <label htmlFor="dbUserName_prod">Username</label>

                      <InputText
                        id="dbUserName_prod"
                        name="username_prod"
                        onChange={handleDbInputChange}
                        value={dbValue.username_prod}
                        required
                        style={{ width: "100%", fontSize: "1.2em" }}
                        invalid={dbError.username_prod}
                      />
                      <label htmlFor="dbPassword_prod">Password</label>

                      <Password
                        className="w-full"
                        id="dbPassword_prod"
                        name="password_prod"
                        type="password_prod"
                        toggleMask
                        onChange={handleDbInputChange}
                        value={dbValue.password_prod}
                        required
                        invalid={dbError.password_prod}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          label="Verify..."
                          type="test"
                          icon="pi pi-check"
                          severity="primary"
                          onClick={() => handleDbTestConnection("prod")}
                          className="border-round-lg"
                          style={{
                            marginTop: "4px",
                            padding: "6px 12px", // Adjust padding to decrease height
                            fontSize: "12px", // Adjust font size if needed
                          }}
                        />
                      </div>

                      <ConnectionTestDialog
                        visible={dbtestvisible}
                        setVisible={setDbTestVisible}
                        loading={testloading}
                        responseStatus={responseStatus}
                        responseMessage={responseMessage}
                      />
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
}
