import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { useUserContext } from "../components/contextAPIs/UserContext";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import {
  getEditUserService,
  UpdateUserService,
} from "../API Services/PagesServices/UserService";
// import EncryptDataComponent from "../Encrypt API/Encrypt";

import {
  handleEncryptedData,
  // getPublicKeyInstance,
} from "../Encrypt API/ModifiedEncrypt";

const UserRegistration = ({ onClose }) => {
  const { getRole } = useUserContext();
  const role = getRole();
  const toast = useRef(null);
  const mail = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  //  const {handleEncryptedRequest} = EncryptDataComponent();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getEditUserService(mail);
        const data = response.data;
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...data,
          userpassword: "",
        }));
      } catch (e) {
        if (e.response && e.response.status === 401) {
          navigate("/login");
        } else {
          console.error(e);
        }
      }
    };
    fetchData();
  }, [mail]);

  //const handleChange = (e) => {
  //  const { name, value } = e.target;
  // setFormData({
  //    ...formData,
  //    [name]: value,

  //    });
  //};

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      if (value.length <= 10) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const [displayDialog, setDisplayDialog] = useState(false);
  const dialogFooter = (
    <div>
      <Button
        label="Close"
        onClick={() => {
          setDisplayDialog(false);
          // Navigate to home page when closing dialog
          navigate("/login");
        }}
      />
    </div>
  );
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (showPasswordFields) {
      if (formData.userpassword !== formData.repeatPassword) {
        toast.current.show({
          severity: "error",
          summary: "Warning",
          detail: "Passwords do not match",
          life: 6000,
        });
        return; // Stop execution if passwords do not match
      }
    }

    // const publicKey = await getPublicKeyInstance();
    try {
      setLoading(true);
      // const encryptedData = await handleEncryptedRequest(formData);

      const encryptedData = await handleEncryptedData(formData);

      await UpdateUserService(mail, encryptedData);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "profile updated successfully",
        life: 6000,
      });

      setDisplayDialog(true);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        navigate("/login");
      } else {
        console.error(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const genderOptions = ["Male", "Female"];
  const permissionsList = ["ADMIN", "USER", "DEV", "QA"];

  const [showPasswordFields, setShowPasswordFields] = useState(false);

  //handling the change password checkbox
  const handlePasswordCheckboxChange = (e) => {
    setShowPasswordFields(e.checked);
  };

  return (
    <>
      <Toast ref={toast} />
      {/* {console.log(formData)} */}
      <Sidebar
        visible={true}
        position="right"
        onHide={onClose}
        className="w-full md:w-4 lg:w-3"
      >
        <h2>Profile</h2>
        <div className="w-full mt-5">
          <form onSubmit={handleSubmit} className="flex flex-column gap-5">
            <FloatLabel>
              <InputText
                id="firstName"
                name="firstName"
                className="w-full p-inputtext-sm"
                value={formData.firstName}
                onChange={handleChange}
                required
                disabled={role !== "ADMIN"}
              />
              <label htmlFor="firstName">First Name</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="lastName"
                name="lastName"
                className="w-full p-inputtext-sm"
                value={formData.lastName}
                onChange={handleChange}
                required
                disabled={role !== "ADMIN"}
              />
              <label htmlFor="lastName">Last Name</label>
            </FloatLabel>

            <div className="flex flex-column mt-m1">
              <label htmlFor="gender" className="text-xs">
                Gender
              </label>
              <SelectButton
                id="gender"
                name="gender"
                className="p-selectbutton-xs"
                value={formData.gender}
                onChange={handleChange}
                options={genderOptions}
                disabled={role !== "ADMIN"}
              />
            </div>

            <FloatLabel>
              <InputText
                id="email"
                name="email"
                type="email"
                className="w-full p-inputtext-sm"
                value={formData.email}
                // onChange={handleChange}
                // required
                // disabled
                disabled={role !== "ADMIN"}
              />
              <label htmlFor="email">Email</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="phone"
                name="phone"
                // type="tel"
                keyfilter="int"
                className="w-full p-inputtext-sm"
                value={formData.phone}
                onChange={handleChange}
                required
                disabled={role !== "ADMIN"}
              />
              <label htmlFor="phone">Phone Number</label>
            </FloatLabel>

            {/*}		<FloatLabel>
              <InputText
                id="company"
                name="company"
                type="company"
                className="w-full p-inputtext-sm"
                value={formData.company}
                onChange={handleChange}
                required
              />
              <label htmlFor="phone">Company Code</label>
            </FloatLabel> */}

            <FloatLabel>
              <InputText
                id="designation"
                name="designation"
                className="w-full p-inputtext-sm"
                value={formData.designation}
                onChange={handleChange}
                required
                disabled={role !== "ADMIN"}
              />
              <label htmlFor="designation">Designation</label>
            </FloatLabel>

            <FloatLabel>
              <Dropdown
                value={formData.permissions}
                onChange={handleChange}
                options={permissionsList}
                placeholder="Select Permissions"
                maxSelectedLabels={3}
                className="w-full"
                name="permissions"
                disabled
              />

              <label htmlFor="permissions">Permissions</label>
            </FloatLabel>

            {role === "ADMIN" && (
              <div className="flex align-items-center">
                <Checkbox
                  inputId="changePassword"
                  checked={showPasswordFields}
                  onChange={handlePasswordCheckboxChange}
                />
                <label htmlFor="changePassword" className="ml-2">
                  Change Password
                </label>
              </div>
            )}

            {showPasswordFields && (
              <>
                <FloatLabel>
                  <Password
                    id="userpassword"
                    name="userpassword"
                    className="w-full"
                    value={formData.userpassword}
                    required
                    feedback={false}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <label htmlFor="userpassword">Password</label>
                </FloatLabel>

                <FloatLabel>
                  <Password
                    id="repeatPassword"
                    name="repeatPassword"
                    className="w-full"
                    value={formData.repeatPassword}
                    required
                    feedback={false}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <label htmlFor="repeatPassword">Confirm Password</label>
                </FloatLabel>
              </>
            )}

            <Button
              label={"Update Profile"}
              type="submit"
              icon="pi pi-check"
              severity="primary"
              className="border-round-lg"
              disabled={role !== "ADMIN"}
              loading={loading}
            />
          </form>
        </div>
      </Sidebar>
      <Dialog
        header="Profile Update"
        visible={displayDialog}
        style={{ width: "50vw" }}
        footer={dialogFooter}
        onHide={() => {
          setDisplayDialog(false); // Close the dialog
          navigate("/login"); // Navigate to the home page
        }}
      >
        <p>You have recently Updated your profile Please Login again</p>
      </Dialog>
      ;
    </>
  );
};

export default UserRegistration;
