import React, { useState, useContext, useRef, useEffect } from "react";
import { ContextTimeScheduling } from "../components/scheduleComponent/ContextTimeScheduling";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import Breadcrumbs from "../components/Breadcrumb";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { useUserContext } from "../components/contextAPIs/UserContext";
import {
  TestConnectionDBService,
  TestConnectionSFTP2Service,
  // TestConnectionSFTPService,
} from "../API Services/TestConnectionService";
// import EncryptDataComponent from "../Encrypt API/Encrypt";
import { handleEncryptedData } from "../Encrypt API/ModifiedEncrypt";

import { checkEmptyField } from "../common/handleEmptyField";
import ConnectionTestDialog from "../Dialog/ConnectionTestDialog";

export default function SftptoDbTransfer() {
  const { setOpenDialog, setcontextFormData } = useContext(
    ContextTimeScheduling
  );
  // const { handleEncryptedRequest } = EncryptDataComponent();

  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { dbResponse, sftpResponse, responsePGP } = location.state || {};

  const { getRole } = useUserContext();
  const role = getRole();

  const [isFolderChecked, setIsFolderChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedDBOption, setSelectedDBOption] = useState("");
  const [selectedPGPOption, setSelectedPGPOption] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [queryChecked, setQueryChecked] = useState(false);

  const [formData, setFormData] = useState({
    sourceSecretName: "",
    server1: "",
    port: "",
    sftpUser: "",
    sftpPass: "",
    filePath: "",
    filepath1Copy: "",
    fileName: "",
    filePattern: "",
    isfolder: false,
    insertQuery: "",
    addQuery: false,
    encrypt: false,
    pgpSecret: "",
    targetSecretName: "",
    tableName: "",
    dbUrl: "",
    dbUser: "",
    dbPass: "",
    jobname: "",
    archiveLocation: "",
    jiraTicketNumber: "",
    headers:"",
  });

  // added for test connection for sftp1

  // added for the database connection

  //Dropdown
  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = sftpResponse.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      const environmentData =
        role === "QA" ? selectedData["qa"] : selectedData["dev"];
      setFormData((prevState) => ({
        ...prevState,
        sourceSecretName: selectedName.name || "",
        server1: environmentData?.host || "",
        port: environmentData?.port || "",
        sftpUser: environmentData?.username || "",
        sftpPass: environmentData?.password || "",
      }));
    }
  };

  //Dropdown
  const handleDBSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedDBOption(selectedName);

    const selectedData = dbResponse.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      const environmentData =
        role === "QA" ? selectedData["qa"] : selectedData["dev"];
      setFormData((prevState) => ({
        ...prevState,
        targetSecretName: selectedName.name || "",
        dbUrl: environmentData?.dburl || "",
        dbUser: environmentData?.username || "",
        dbPass: environmentData?.password || "",
      }));
    }
  };
  //sftp validation
  const [sftpError, setsftpError] = useState({});
  const [dbError, setDbError] = useState({});
  const checkDetailsValid = (serverData, btnNo) => {
    formData.filepath1Copy = formData.filePath;
    let errors = checkEmptyField(serverData);
    console.log("Errors", serverData);
    if (btnNo === 1 && !isFolderChecked && formData.fileName.trim() === "")
      errors.fileName = "file name can't be empty";
    if (btnNo === 1 && formData.filepath1Copy.trim() === "")
      errors.filepath1Copy = "file path can't be empty";
    btnNo === 1 ? setsftpError(errors) : setDbError(errors);
    if (Object.keys(errors).length === 0) {
      callTestSFTP(serverData, btnNo);
      setLoading(true);
      setVisible(true);
    } else return;
  };

  useEffect(() => {
    if (isFolderChecked) {
      formData.fileName = "";
      formData.isfolder = true;
    } else {
      formData.isfolder = false;
      formData.filePattern = "";
    }
  }, [isFolderChecked]);

  const callTestSFTP = async (serverData, btnNo) => {
    try {
      console.log("this is the server data", serverData);
      // const encryptedData = await handleEncryptedRequest(serverData);
      const encryptedData = await handleEncryptedData(serverData);
      let sftp_connect =
        btnNo === 1
          ? // ? await TestConnectionSFTPService(encryptedData)
            await TestConnectionSFTP2Service(serverData)
          : await TestConnectionDBService(encryptedData);
      // console.log("this is the sftp connection",sftp_connect)
      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      console.log(e);
      let errorResponse = e.response ? e.response : null;
      if (errorResponse == null) {
        setResponseMessage("Server Link Failed");
        setResponseStatus("500");

        setTimeout(() => {
          localStorage.clear();
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Network Error",
          detail: "Server Link Failure",
          life: 2000,
        });

        return;
      } else if (errorResponse.status === 401) {
        setResponseMessage("Session Expired ");
        setResponseStatus("401");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Session Timeout",
          detail: "Session Expired!, Try Login Again.",
          life: 2000,
        });
        return;
      } else {
        setResponseMessage(errorResponse.data.messages || "Failed to connect.");
        setResponseStatus(errorResponse.data.status || "Error");
      }
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  const handleTestConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      const serverData = {
        server1: formData.server1,
        port1: formData.port,
        user1: formData.sftpUser,
        pass1: formData.sftpPass,
        filePath: formData.filePath + "/" + formData.fileName,
      };
      checkDetailsValid(serverData, buttonType);
    } else {
      const dataBaseDetails = {
        dbUrl: formData.dbUrl,
        dbUser: formData.dbUser,
        dbPass: formData.dbPass,
        tableName: queryChecked ? null : formData.tableName,
        query: queryChecked ? formData.insertQuery : null,
        jiraTicketNumber: formData.jiraTicketNumber,
      };
      checkDetailsValid(dataBaseDetails, buttonType);
    }
  };

  // handle test connecti

  const validateForm = () => {
    const requiredField = [
      "sourceSecretName",
      "filePath",
      "targetSecretName",
      //"tableName",
      "jobname",
      "archiveLocation",
      "jiraTicketNumber",
    ];
    if (!queryChecked) {
      requiredField.push("tableName");
    }

    const missingFileds = requiredField.filter((item) => !formData[item]);

    if (formData.encrypt && !formData.pgpSecret) {
      missingFileds.push("pgpSecret");
    }
    if (!formData.isfolder && !formData.fileName) {
      missingFileds.push("fileName");
    }
    if (formData.isfolder && !formData.filePattern) {
      missingFileds.push("filePattern");

      return false;
    }
    // if (formData.addQuery && !formData.insertQuery) {
    //   missingFileds.push("insertQuery");
    // }
    if (queryChecked && !formData.insertQuery) {
      missingFileds.push("insertQuery");
    }

    if (missingFileds.length > 0) {
      // console.log(missingFileds, " in the length of missing fields ");
      setErrorFields(missingFileds);
      return false;
    }
    setErrorFields([]);
    return true; // All fields are filled
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all fields.",
        life: 3000,
      });
    }
  };

  // console.log(formData);

  ////////////////////////////////////////////////////////////////
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    if (name === "addQuery" && !val) {
      // Reset queryText if addQuery is unchecked
      setFormData({ ...formData, [name]: val, insertQuery: "" });
    } else {
      setFormData({ ...formData, [name]: val });
    }
  };

  const handlePGPChecked = (e) => {
    const { name, checked } = e.target;
    // console.log(name, checked);
    setFormData((prev) => ({ ...prev, encrypt: checked }));
    if (checked === false) {
      setFormData((prev) => ({ ...prev, pgpSecret: "" }));
      setSelectedPGPOption(null);
    }
  };

  const handlePGPSelectChange = (e) => {
    setSelectedPGPOption(e.value);
    setFormData((prev) => ({ ...prev, pgpSecret: e.value }));
  };

  // console.log(formData);
  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  return (
    <>
      <Breadcrumbs />

      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <span className="text-2xl font-bold">
                Mount To Database Transfer
              </span>
            </div>
            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  invalid={errorFields.includes("jobname")}
                />
              </div>
            </div>

            <div className="col-12 sm:col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={sftpResponse || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Source SFTP"
                      className="w-full"
                      invalid={
                        sftpError.server1 ||
                        errorFields.includes("sourceSecretName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path_input"
                      name="filePath"
                      className="w-full"
                      value={formData.filePath}
                      onChange={handleChange}
                      placeholder="Enter Source Path"
                      invalid={
                        sftpError.filepath1Copy ||
                        errorFields.includes("filePath")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="multipleFiles"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      All Files in the Folder
                    </label>
                    <Checkbox
                      inputId="multipleFiles"
                      name="multipleFiles"
                      value={formData.isfolder}
                      onChange={(e) => setIsFolderChecked(e.checked)}
                      checked={isFolderChecked}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File Name
                    </label>
                    <InputText
                      id="file_name_input"
                      name="fileName"
                      className="w-full"
                      value={formData.fileName}
                      onChange={handleChange}
                      placeholder="Enter File Name"
                      disabled={isFolderChecked}
                      invalid={
                        (!isFolderChecked && sftpError.fileName) ||
                        errorFields.includes("fileName")
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="files_pattern"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File patterns
                    </label>
                    <InputText
                      id="file_Pattern"
                      name="filePattern"
                      className="w-full"
                      value={formData.filePattern}
                      onChange={handleChange}
                      placeholder="Enter File Patterns"
                      disabled={!isFolderChecked}
                      invalid={
                        isFolderChecked && errorFields.includes("filePattern")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="encrypt"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Decryption
                    </label>
                    <Checkbox
                      inputId="encrypt"
                      name="encrypt"
                      checked={formData.encrypt}
                      onChange={handlePGPChecked}
                    />
                  </div>

                  {formData.encrypt && (
                    <div className="flex-auto">
                      <label
                        htmlFor="dns_input"
                        className="font-semibold text-xs block mx-1 mb-1 mt-3"
                      >
                        Select PGP Secret
                      </label>
                      <Dropdown
                        value={selectedPGPOption || null}
                        // onChange={handleSftpSelectChange}
                        // options={sftpResponse || []}
                        onChange={handlePGPSelectChange}
                        options={responsePGP || []}
                        // optionLabel="name"
                        // showClear
                        placeholder="Select a PGP Secret"
                        className="w-full"
                        invalid={errorFields.includes("pgpSecret")}
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="archiveLocation_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Archive Path
                    </label>
                    <InputText
                      id="archiveLocation_input"
                      name="archiveLocation"
                      className="w-full"
                      value={formData.archiveLocation}
                      onChange={handleChange}
                      placeholder="Enter Archive Path"
                      invalid={errorFields.includes("archiveLocation")}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="headers"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Input headers
                    </label>
                    <InputTextarea
                      id="headers"
                      name="headers"
                      className="w-full"
                      value={formData.headers}
                      onChange={handleChange}
                      placeholder="Enter The Headers"
                      // invalid={errorFields.includes("headers")}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select DataBase Details
                    </label>
                    <Dropdown
                      value={selectedDBOption || null}
                      onChange={handleDBSelectChange}
                      options={dbResponse || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Target Database"
                      className="w-full"
                      invalid={
                        dbError.dbUser ||
                        errorFields.includes("targetSecretName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="tableName_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="tableName_input"
                      name="tableName"
                      className="w-full"
                      value={formData.tableName}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                      disabled={queryChecked}
                      invalid={
                        dbError.tableName || errorFields.includes("tableName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="addQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="addQuery"
                      name="addQuery"
                      checked={formData.addQuery}
                      onChange={(e) => {
                        handleChange(e);
                        setQueryChecked(e.target.checked);
                        if (e.target.checked) {
                          setFormData((prevData) => ({
                            ...prevData,
                            tableName: "",
                          }));
                        }
                      }}
                    />
                  </div>

                  {queryChecked && (
                    <div className="group">
                      <InputTextarea
                        name="insertQuery"
                        placeholder="Enter  SQL query here"
                        className="w-full"
                        autoResize
                        value={formData.insertQuery}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                        invalid={
                          dbError.query || errorFields.includes("insertQuery")
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Jira Ticket Number
                    </label>
                    <InputText
                      id="path2_input"
                      name="jiraTicketNumber"
                      className="w-full"
                      value={formData.jiraTicketNumber}
                      onChange={handleChange}
                      placeholder="Enter Jira Ticket Number"
                      invalid={
                        dbError.jiraTicketNumber ||
                        errorFields.includes("jiraTicketNumber")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 2)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
                <ConnectionTestDialog
                  visible={visible}
                  setVisible={setVisible}
                  loading={loading}
                  responseStatus={responseStatus}
                  responseMessage={responseMessage}
                />
              </div>
            </div>

            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
            </div>
          </div>
        </form>
        <Toast ref={toast} />
      </div>
    </>
  );
}
