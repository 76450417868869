import React, { useState, useContext, useRef, useEffect } from "react";
import { ContextTimeScheduling } from "../components/scheduleComponent/ContextTimeScheduling";
import { useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Breadcrumbs from "../components/Breadcrumb";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
// import { createAxiosInstance as myAxios } from "../services/Helper";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { useUserContext } from "../components/contextAPIs/UserContext";
import { TestConnectionDBService } from "../API Services/TestConnectionService";
// import EncryptDataComponent from "../Encrypt API/Encrypt";
import { handleEncryptedData } from "../Encrypt API/ModifiedEncrypt";

import { checkEmptyField } from "../common/handleEmptyField";
import ConnectionTestDialog from "../Dialog/ConnectionTestDialog";

const DatabaseTransferFile = () => {
  // const navigate = useNavigate();
  // const { handleEncryptedRequest } = EncryptDataComponent();

  const toast = useRef(null);
  const location = useLocation();
  const { response } = location.state || {};
  const { getRole } = useUserContext();
  const role = getRole();

  const { setOpenDialog, setcontextFormData } = useContext(
    ContextTimeScheduling
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [limitErrorMessage, setLimitErrorMessage] = useState("");
  const [isLimitValid, setIsLimitValid] = useState(true);

  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedSftpOption2, setSelectedSftpOption2] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [sourceQueryChecked, setSourceQueryChecked] = useState(false);
  const [targetQueryChecked, setTargetQueryCheked] = useState(false);

  const [formData, setFormData] = useState({
    sourceSecretName: "",
    jobname: "",
    sourceDbUrl: "",
    sourceTable: "",
    sourceDbUser: "",
    sourceDbPassword: "",
    queryText: "",
    addQuery: false,
    targetSecretName: "",
    targetTable: "",
    targetDbUrl: "",
    targetDbUser: "",
    targetDbPassword: "",
    insertQueryText: "",
    isInsertQuery: "",
    jiraTicketNumber: "",
  });

  //Dropdown
  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = response.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      const environmentData =
        role === "QA" ? selectedData["qa"] : selectedData["dev"];
      setFormData((prevState) => ({
        ...prevState,
        sourceSecretName: selectedName.name || "",
        sourceDbUrl: environmentData?.dburl || "",
        sourceDbUser: environmentData?.username || "",
        sourceDbPassword: environmentData?.password || "",
      }));
    }
  };

  const handleSftpSelectChange2 = (event) => {
    const selectedName2 = event.target.value;
    setSelectedSftpOption2(selectedName2);

    const selectedData2 = response.find(
      (item) => item.name === selectedName2.name
    );
    if (selectedData2) {
      const environmentData =
        role === "QA" ? selectedData2["qa"] : selectedData2["dev"];
      setFormData((prevState) => ({
        ...prevState,
        targetSecretName: selectedName2.name || "",
        targetDbUrl: environmentData?.dburl || "",
        targetDbUser: environmentData?.username || "",
        targetDbPassword: environmentData?.password || "",
      }));
    }
  };
  // database validation
  const [dbError1, setDbError1] = useState({});
  const [dbError2, setDbError2] = useState({});
  const checkDetailsValid = (serverData, btnNo) => {
    formData.filepath1Copy = formData.filePath;
    let errors = checkEmptyField(serverData);
    // console.log("this is the errosrs", errors);
    btnNo === 1 ? setDbError1(errors) : setDbError2(errors);
    if (Object.keys(errors).length === 0) {
      callTestDatabase(serverData);
      setLoading(true);
      setVisible(true);
    } else return;
  };
  const callTestDatabase = async (serverData) => {
    try {
      // const encryptedData = await handleEncryptedRequest(serverData);

      const encryptedData = await handleEncryptedData(serverData);

      let sftp_connect = await TestConnectionDBService(encryptedData);

      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      // update code added here for message and status
      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      let errorResponse = e.response.data;
      setResponseMessage(errorResponse.messages || "Failed to connect.");
      setResponseStatus(errorResponse.status || "Error");
    } finally {
      setLoading(false);
    }
  };

  const handleTestForDBConnection = async (e, buttonType) => {
    e.preventDefault();
    const dbDetails =
      buttonType === 1
        ? {
            dbUrl: formData.sourceDbUrl,
            dbUser: formData.sourceDbUser,
            dbPass: formData.sourceDbPassword,
            tableName: sourceQueryChecked ? null : formData.sourceTable,
            query: sourceQueryChecked ? formData.queryText : null,
          }
        : {
            dbUrl: formData.targetDbUrl,
            dbUser: formData.targetDbUser,
            dbPass: formData.targetDbPassword,
            tableName: targetQueryChecked ? null : formData.targetTable,
            jiraTicketNumber: formData.jiraTicketNumber,
            query: targetQueryChecked ? formData.insertQueryText : null,
          };
    checkDetailsValid(dbDetails, buttonType);
  };

  const validateForm = () => {
    const listofValidateFields = [
      "jobname",
      "sourceSecretName",
      "targetSecretName",
      //"sourceTable",
      //"targetTable",
      "jiraTicketNumber",
    ];

    if (!sourceQueryChecked) {
      listofValidateFields.push("sourceTable");
    }

    if (!targetQueryChecked) {
      listofValidateFields.push("targetTable");
    }

    const missingFields = listofValidateFields.filter(
      (name) => !formData[name]
    );

    if (sourceQueryChecked && !formData.queryText) {
      missingFields.push("queryText");
      // return false;
    }

    if (targetQueryChecked && !formData.insertQueryText) {
      missingFields.push("insertQueryText");
    }

    // if (formData.addQuery && !formData.queryText) {
    //   missingFields.push("queryText");
    //   // return false;
    // }
    // if (formData.isInsertQuery && !formData.insertQueryText) {
    //   missingFields.push("insertQueryText");
    //   // return false ;
    // }
    if (missingFields.length > 0) {
      setErrorFields(missingFields);
      return false;
    }
    setErrorFields([]);
    return true;
  };

  const handleClick = (e) => {
    e.preventDefault();

    // adding for limit query error
    if (!isLimitValid) {
      toast.current.show({
        severity: "error",
        summary: "SQL Query Limit ",
        detail: "You can take Limited Query Amount else Opt for offset",
        life: 2000,
      });
      return;
    }

    // console.log(validateForm(), "form is valid or not ");
    if (!validateForm()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Please fill all required fields.",
      });
    } else {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    }
  };

  // useEffect to see the values
  useEffect(() => {
    // Regular expression to find the number after the LIMIT keyword
    const limitMatch = formData.queryText.match(/LIMIT\s+(\d+)/i);

    if (limitMatch) {
      const limitValue = limitMatch[1]; // Extracted number
      if (limitValue > 100000) {
        setLimitErrorMessage("Cant' Exceed more than 1 lakh");
        setIsLimitValid(false);
      } else {
        setLimitErrorMessage("");
        setIsLimitValid(true);
      }
    } else {
      setLimitErrorMessage("");
      setIsLimitValid(true);
    }
  }, [formData.queryText, limitErrorMessage]);

  /////////////////////////////////////////////////////////
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    if (name === "addQuery" && !val) {
      // Reset queryText if addQuery is unchecked
      setFormData({ ...formData, [name]: val, queryText: "" });
    } else if (name == "isInsertQuery" && !val) {
      setFormData({ ...formData, [name]: val, insertQueryText: "" });
    } else {
      setFormData({ ...formData, [name]: val });
    }
  };

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  return (
    <>
      <Breadcrumbs />

      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <span className="text-2xl font-bold">
                Database To Database Transfer
              </span>
            </div>

            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  invalid={errorFields.includes("jobname")}
                />
              </div>
            </div>

            <div className="col-12 sm:col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select Database Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={response || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Source Database"
                      className="w-full"
                      invalid={
                        dbError1.dbUrl ||
                        errorFields.includes("sourceSecretName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="tableName_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="tableName_input"
                      name="sourceTable"
                      className="w-full"
                      value={formData.sourceTable}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                      disabled={sourceQueryChecked}
                      invalid={
                        dbError1.tableName ||
                        errorFields.includes("sourceTable")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="addQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="addQuery"
                      name="addQuery"
                      checked={formData.addQuery}
                      onChange={(e) => {
                        handleChange(e);

                        setSourceQueryChecked(e.target.checked);
                        if (e.target.checked) {
                          setFormData((prevData) => ({
                            ...prevData,
                            tableName: "",
                          }));
                        }
                      }}
                    />
                  </div>

                  {sourceQueryChecked && (
                    <div className="group">
                      {limitErrorMessage && (
                        <p style={{ color: "red" }}>{limitErrorMessage}</p>
                      )}
                      <InputTextarea
                        name="queryText"
                        placeholder="Enter SQL query here"
                        className="w-full"
                        autoResize
                        value={formData.queryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                        invalid={
                          dbError1.query || errorFields.includes("queryText")
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestForDBConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select Database Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption2 || null}
                      onChange={handleSftpSelectChange2}
                      options={response || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Target Database"
                      className="w-full"
                      invalid={
                        dbError2.dbUrl ||
                        errorFields.includes("targetSecretName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="targetTable"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="targetTable"
                      name="targetTable"
                      className="w-full"
                      value={formData.targetTable}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                      disabled={targetQueryChecked}
                      invalid={
                        dbError2.tableName ||
                        errorFields.includes("targetTable")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="isInsertQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="isInsertQuery"
                      name="isInsertQuery"
                      checked={formData.isInsertQuery}
                      onChange={(e) => {
                        handleChange(e);

                        setTargetQueryCheked(e.target.checked);
                        if (e.target.checked) {
                          setFormData((previous) => ({
                            ...previous,
                            tableName: "",
                          }));
                        }
                      }}
                    />
                  </div>

                  {targetQueryChecked && (
                    <div className="group">
                      <InputTextarea
                        name="insertQueryText"
                        placeholder="Enter SQL query here(insert query)"
                        className="w-full"
                        autoResize
                        value={formData.insertQueryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                        invalid={
                          dbError2.query ||
                          errorFields.includes("insertQueryText")
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Jira Ticket Number
                    </label>
                    <InputText
                      id="path2_input"
                      name="jiraTicketNumber"
                      className="w-full"
                      value={formData.jiraTicketNumber}
                      onChange={handleChange}
                      placeholder="Enter Jira Ticket Number"
                      invalid={
                        dbError2.jiraTicketNumber ||
                        errorFields.includes("jiraTicketNumber")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestForDBConnection(e, 2)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>

                <ConnectionTestDialog
                  visible={visible}
                  setVisible={setVisible}
                  loading={loading}
                  responseStatus={responseStatus}
                  responseMessage={responseMessage}
                />
              </div>
            </div>

            <div className="col-4"></div>
            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
            </div>
          </div>
        </form>
        <Toast ref={toast} />
      </div>
    </>
  );
};
export default DatabaseTransferFile;
