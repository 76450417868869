import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import Breadcrumbs from "../../components/Breadcrumb";
import { ContextTimeScheduling } from "../../components/scheduleComponent/ContextTimeScheduling";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { viewRolesdtm, transferDept } from "./viewRoleApis";
import "../css/rule.css";
import {
  TestConnectionDBService,
  TestConnectionSFTPService,
} from "../../API Services/TestConnectionService";
import {
  fetchVersionDataApidtm,
  migrateJobdtm,
  reuseVersionApidtm,
} from "../../API Services/RulesService/dtmServices";
import { migrateJobValidation } from "../../API Services/RulesService/dtdServices";
// import EncryptDataComponent from "../../Encrypt API/Encrypt";

import {
  handleEncryptedData,
  // getPublicKeyInstance,
} from "../../Encrypt API/ModifiedEncrypt";

import { checkEmptyField } from "../../common/handleEmptyField";
import ConnectionTestDialog from "../../Dialog/ConnectionTestDialog";
import { Skeleton } from "primereact/skeleton";

export default function DbToMountRules() {
  const [ruleData, setRuleData] = useState(null);
  const [migrationLoading, setMigrationLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  ///Here state for test connection
  // const [loadingTest, setLoadingTest] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  //added for limit query.
  const [limitErrorMessage, setLimitErrorMessage] = useState("");
  const [isLimitValid, setIsLimitValid] = useState(true);

  const location = useLocation();
  const { ruleId, viewRole } = location.state || {};
  const toast = useRef(null);
  const { setOpenDialog, setcontextFormData, setSelectedViewAs } = useContext(
    ContextTimeScheduling
  );

  const { dbResponse, sftpResponse, responsePGP } = location.state || {};
  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedDBOption, setSelectedDBOption] = useState("");
  const [selectedPGPOption, setSelectedPGPOption] = useState("");

  const [objectData, setObjectData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [codeStatus, setCodeStatus] = useState("");
  // const { handleEncryptedRequest } = EncryptDataComponent();

  const [errorFields, setErrorFields] = useState([]);
  const [queryChecked, setQueryChecked] = useState(false);

  const [formData, setFormData] = useState({
    sourceSecretName: "",
    server1: "",
    port: "",
    sftpUser: "",
    sftpPass: "",
    filePath: "",
    fileName: "",
    tableName: "",
    targetSecretName: "",
    jobname: "",
    dbUrl: "",
    dbUser: "",
    dbPass: "",
    archiveLocation: "",
    minutes: "",
    hours: "",
    months: "",
    days: "",
    weeks: "",
    frequencyType: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    ruleId: "",
    queryText: "",
    addQuery: false,
    jiraTicketNumber: "",
    version: "",
    versions: [],
    encrypt: false,
    pgpSecret: "",
    previousTable: "",
    previousQueryText: "",
  });

  useEffect(() => {
    if (ruleId === undefined) navigate("/home");
    setSelectedViewAs(viewRole);
    const fetchData = async () => {
      try {
        // // console.log("This is the rule Id :" + ruleId);
        const axiosInstance = await myAxios();
        if (viewRole === "DEV") {
          const response = await axiosInstance.get(
            `${viewRolesdtm[0].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "QA") {
          const response = await axiosInstance.get(
            `${viewRolesdtm[1].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "PROD") {
          const response = await axiosInstance.get(
            `${viewRolesdtm[2].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        }
      } catch (error) {
        // console.log(error);
        // console.log(error.response);
        let errorValue = error.response ? error.response : null;
        // console.log(errorValue);
        if (errorValue == null) {
          navigate("/login");
        } else if (errorValue.status === 401) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Token Expired ",
            detail: "Session Time Out ",
            life: 2000,
          });
        } else if (errorValue.status === 403) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Access Denied",
            detail: "Try Login Again.",
            life: 2000,
          });
        }

        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (ruleId) {
      fetchData();
    }
  }, [ruleId]);

  ////////////////////////////////////////////////////
  //this is the handle dropdown
  //Dropdown
  const handleDBSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedDBOption(selectedName);

    const selectedData = dbResponse.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData["dev"];
      }
      setFormData((prevState) => ({
        ...prevState,
        sourceSecretName: selectedName.name || "",
        dbUrl: environmentData?.dburl || "",
        dbUser: environmentData?.username || "",
        dbPass: environmentData?.password || "",
      }));
    }
  };

  //Dropdown
  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = sftpResponse.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData["dev"];
      }
      setFormData((prevState) => ({
        ...prevState,
        targetSecretName: selectedName.name || "",
        server1: environmentData?.host || "",
        port: environmentData?.port || "",
        sftpUser: environmentData?.username || "",
        sftpPass: environmentData?.password || "",
      }));
    }
  };

  //////////////////////////////////
  // this function is used to set the data .
  // setSelectedSftpOption(selectedName);
  const setServerData = (secretName) => {
    sftpResponse?.map((resp) => {
      if (resp.name === secretName) {
        setSelectedSftpOption(resp);
        // console.log(resp)
      }
    });
  };

  const setServerData2 = (secretName2) => {
    dbResponse?.map((resp) => {
      if (resp.name === secretName2) {
        setSelectedDBOption(resp);
        // console.log(resp)
      }
    });
  };

  const setPgpData = (pgpSecret) => {
    responsePGP?.map((resp) => {
      if (resp.name === pgpSecret) {
        setSelectedDBOption(resp);
        // console.log(resp)
      }
    });
  };

  useEffect(() => {
    if (ruleData) {
      // console.log(ruleData, " getting from the edit form data ");
      const startTime = ruleData.startDate
        ? ruleData.startDate.split("T")[1]
        : "";

      setServerData(ruleData?.targetSecretName);
      setServerData2(ruleData?.sourceSecretName);
      setPgpData(ruleData?.pgpSecret);
      setSelectedPGPOption(ruleData.pgpSecret);

      setFormData({
        sourceSecretName: ruleData.sourceSecretName || "",
        server1: ruleData.server || "",
        port: ruleData.port || "",
        sftpUser: ruleData.sftpUser || "",
        sftpPass: ruleData.sftpPass || "",
        filePath: ruleData.filePath || "",
        fileName: ruleData.fileName || "",
        jobname: ruleData.jobname || "",
        targetSecretName: ruleData.targetSecretName || "",
        tableName: ruleData.tableName || "",
        dbUrl: ruleData.dbUrl || "",
        dbUser: ruleData.dbUser || "",
        dbPass: ruleData.dbPass || "",
        archiveLocation: ruleData.archiveLocation || "",
        minutes: ruleData.minutes || "",
        hours: ruleData.hours || "",
        months: ruleData.months || "",
        days: ruleData.days || "",
        weeks: ruleData.weeks || "",
        frequencyType: ruleData.frequencyType || "",
        timeZone: ruleData.timeZone || "",
        startDate: ruleData.startDate || "",
        startTime: startTime || "",
        endDate: ruleData.endDate || "",
        ruleId: ruleData.id || "",
        queryText: ruleData.queryText || "",
        addQuery: !!ruleData.queryText,
        jiraTicketNumber: ruleData.jiraTicketNumber || "",
        version: ruleData.version || "",
        versions: ruleData.versions || [],
        pgpSecret: ruleData.pgpSecret || "",
        encrypt: !!ruleData.pgpSecret || false,
      });
    }
  }, [ruleData]);

  ///////////////////////////////////////////////////////////////////////////////////////////
  // added for test connection for sftp1

  // useEffect on OutSide the function for Testing DBConnection Test

  const callTestSFTP = async (sftpServer1, btnNo) => {
    try {
      // const axiosInstance = await myAxios();
      // let sftp_connect = await axiosInstance
      //   .post("/test/con/sftp", sftpServer1)
      //   .then((res) => res.data);

      // // console.log(sftp_connect);
      // const encryptedData = await handleEncryptedRequest(sftpServer1);

      // const publicKey = await getPublicKeyInstance();
      const encryptedData = await handleEncryptedData(sftpServer1);

      let sftp_connect =
        btnNo === 1
          ? await TestConnectionSFTPService(encryptedData)
          : await TestConnectionDBService(encryptedData);
      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      let errorResponse = e.response.data;
      setResponseMessage(errorResponse.messages || "Failed to connect.");
      setResponseStatus(errorResponse.status || "Error");
    } finally {
      setLoading(false);
    }
  };

  // handle validation
  const [sftpError, setsftpError] = useState({});
  const [dbError, setDbError] = useState({});
  const checkDetailsValid = (serverData, btnNo) => {
    let errors = checkEmptyField(serverData);
    // console.log("Errors", serverData);
    if (btnNo === 1 && formData.fileName.trim() === "")
      errors.fileName = "file name can't be empty";
    btnNo === 1 ? setsftpError(errors) : setDbError(errors);
    if (Object.keys(errors).length === 0) {
      callTestSFTP(serverData, btnNo);
      setLoading(true);
      setVisible(true);
    } else return;
  };

  // handle Test condition
  const handleTestConnection = async (e, buttonType) => {
    e.preventDefault();
    if (buttonType === 1) {
      const sftpData = {
        server1: formData.server1,
        port1: formData.port,
        user1: formData.sftpUser,
        pass1: formData.sftpPass,
        filePath: formData.filePath,
        ...(ruleData.jiraTicketNumber !== formData.jiraTicketNumber && {
          jiraTicketNumber: formData.jiraTicketNumber,
        }),
      };
      checkDetailsValid(sftpData, buttonType);
    } else {
      const dbData = {
        dbUrl: formData.dbUrl,
        dbUser: formData.dbUser,
        dbPass: formData.dbPass,
        tableName:
          queryChecked || formData.addQuery ? null : formData.tableName,
        query: queryChecked || formData.addQuery ? formData.queryText : null,
      };
      checkDetailsValid(dbData, buttonType);
    }
  };

  const validateForm = () => {
    const requiredField = [
      "jobname",
      "sourceSecretName",
      "targetSecretName",
      "fileName",
      //"tableName",
      "filePath",
      "archiveLocation",
      "jiraTicketNumber",
    ];

    if (!queryChecked && !formData.queryText) {
      requiredField.push("tableName");
    }

    const missingFileds = requiredField.filter((item) => !formData[item]);
    setErrorFields(missingFileds);

    // if (formData.addQuery && !formData.queryText) {
    //   setErrorFields((prev) => [...prev, "queryText"]);
    //   return false;
    // }
    if ((queryChecked || formData.queryText) && !formData.queryText) {
      setErrorFields((prev) => [...prev, "queryText"]);
      return false;
    } else if (formData.encrypt && !formData.pgpSecret) {
      setErrorFields((prev) => [...prev, "pgpSecret"]);
      return false;
    } else if (missingFileds.length > 0) {
      return false;
    }
    setErrorFields([]);
    return true;
  };

  const handleClick = (e) => {
    e.preventDefault();

    // adding for limit query error
    // console.log(isLimitValid,)
    if (!isLimitValid) {
      toast.current.show({
        severity: "error",
        summary: "SQL Query Limit ",
        detail: "You can take Limited Query Amount else Opt for offset",
        life: 2000,
      });
      return;
    }

    // console.log(validateForm(), "form is valid or not ")
    if (!validateForm()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Please fill all required fields.",
      });
    } else {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    }
  };

  //////////////////////////////////////////////////////////////////////

  // useEffect to see the values
  useEffect(() => {
    // Regular expression to find the number after the LIMIT keyword
    const limitMatch = formData.queryText.match(/LIMIT\s+(\d+)/i);

    if (limitMatch) {
      const limitValue = limitMatch[1]; // Extracted number
      if (limitValue > 100000) {
        setLimitErrorMessage("Cant' Exceed more than 1 lakh");
        setIsLimitValid(false);
        // console.log(
        //   "can't go more than 1 lakh records try to break down in smaller chunk with the limit and offset value "
        // );
      } else {
        // console.log("Limit value:", limitValue); // Output: 10
        setLimitErrorMessage("");
        setIsLimitValid(true);
      }
    } else {
      // console.log("No LIMIT clause found");
      setLimitErrorMessage("");
      setIsLimitValid(true);
    }
  }, [formData.queryText, limitErrorMessage]);

  /////////////////////////////////////////////////////////////////

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   const val = type === "checkbox" ? checked : value;
  //   if (name === "addQuery" && !val) {
  //     setFormData({ ...formData, [name]: val, queryText: "" });
  //   } else {
  //     setFormData({ ...formData, [name]: val });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
   
    if (name === "queryText") {
      if (value.trim() !== "") {
        setFormData((prev) => ({
          ...prev,
          [name]: val,
          previousTable: prev.previousTable || prev.tableName,
          tableName: "",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: val,
          previousTable: prev.previousTable,
        }));
      }
    } else if (name === "tableName") {
      if (value.trim() !== "") {
        setFormData((prev) => ({
          ...prev,
          [name]: val,
          previousQueryText: prev.previousQueryText || prev.queryText,
          queryText: "",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: val,
          queryText: prev.previousQueryText,
        }));
      }
    } else {
      setFormData({ ...formData, [name]: val });
    }
  };

  const handlePGPChecked = (e) => {
    const { name, checked } = e.target;
    // console.log(name, checked);
    setFormData((prev) => ({ ...prev, encrypt: checked }));
    if (checked === false) {
      setFormData((prev) => ({ ...prev, pgpSecret: "" }));
      setSelectedPGPOption(null);
    }
  };

  const handlePGPSelectChange = (e) => {
    setSelectedPGPOption(e.value);
    setFormData((prev) => ({ ...prev, pgpSecret: e.value }));
  };

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  // const for the data migration status role
  const [selectedTransferDept, setSelectedTransferDept] = useState("");

  // const { getRole } = useUserContext();

  const filteredOptions = transferDept.filter((option) => {
    if (viewRole === "DEV") return option.code === "qa";
    if (viewRole === "QA") return option.code === "prod";
    return true; // Show all options for admin or other roles
  });

  const accept = async () => {
    setLoading(true);
    try {
      // const axiosInstance = await myAxios();
      // await axiosInstance
      //   .post(
      //     `/moveto${selectedTransferDept.name.toLowerCase()}/dtm`,
      //     objectData?.data
      //   )
      // const encryptedData = await handleEncryptedRequest(objectData);
      // const publicKey = await getPublicKeyInstance();
      const { data } = objectData;

      const encryptedData = await handleEncryptedData(data);

      await migrateJobdtm(selectedTransferDept, encryptedData)
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Successfuly",
            detail: `Job Migrated to ${selectedTransferDept.name}`,
            life: 3000,
          });
        })
        .catch((e) => {
          console.log(e);
          toast.current.show({
            severity: "error",
            summary: `Error While Migration`,
            detail: `${e.response.data}`,
            life: 3000,
          });
        });
      setSelectedTransferDept("");
      setconfirmDialogVisible(false);
    } catch (e) {
      console.log(e);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error while migrating to ${selectedTransferDept.name}`,
        life: 3000,
      });
      setSelectedTransferDept("");
    } finally {
      setLoading(false);
    }
  };

  const reject = () => {
    setSelectedTransferDept("");
    setconfirmDialogVisible(false);
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "Request Cancalled",
      life: 3000,
    });
    setObjectData({});
    setErrorMessage("");
    setCodeStatus("");
  };
  const [confirmDialogVisible, setconfirmDialogVisible] = useState(false);

  const jobMigrate = async () => {
    // const axiosInstance = await myAxios();

    try {
      // const response = await axiosInstance.post(
      //   `/moveto${filteredOptions[0].code}validation?id=${ruleId}&move="moving"&approve="pending"`
      // );
      setconfirmDialogVisible(true);
      setMigrationLoading(true);
      const response = await migrateJobValidation(filteredOptions, ruleId);
      setObjectData(response?.data);
      // console.log(response);
    } catch (err) {
      console.log(err);
      if (err.response?.data?.status === 404) {
        setErrorMessage(err.response?.data?.messages);
        setCodeStatus(err.response?.data?.status);
        setconfirmDialogVisible(true);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An Error Occured",
          life: 3000,
        });
        setconfirmDialogVisible(false);
      }

      setSelectedTransferDept("");
    } finally {
      setMigrationLoading(false);
    }
  };

  const dataMigrate = () => {

    const table = objectData?.data?.tableName;
    return (
      <div>
        <div className="col-12 sm:col-11">
          <div className="flex-auto">
            <label
              htmlFor="jobname"
              className="font-semibold text-xs block mx-1 mb-1"
            >
              Job Name
            </label>
            {migrationLoading ? (
              <Skeleton width="100%" height="2rem" />
            ) : (
              <InputText
                id="jobname"
                name="jobname"
                className="w-full no-highlight"
                value={objectData?.data?.jobname}
                readOnly
                style={{
                  outline: "none" /* Removes the default outline */,
                  boxshadow: "none",
                  backgroundColor: "#dcdcdc",
                  border: "none",
                  color: "#000",
                  fontFamily: "Courier New",
                  overflow: "auto", // Allows scrolling
                  whiteSpace: "nowrap", // Prevents wrapping
                }}
              />
            )}
          </div>
        </div>

        <div className="grid col-12">
          <div className="col-6">
            <div className="grid">
              <div className="col-12">
                <span className="text-lg font-bold uppercase">Source</span>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="dns_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    Source Database Details
                  </label>
                  {migrationLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="jobname"
                      name="jobname"
                      className="w-full no-highlight"
                      value={objectData?.data?.sourceSecretName}
                      readOnly
                      style={{
                        outline: "none" /* Removes the default outline */,
                        boxshadow: "none",
                        backgroundColor: "#dcdcdc",
                        border: "none",
                        color: "#000",
                        fontFamily: "Courier New",
                        overflow: "auto", // Allows scrolling
                        whiteSpace: "nowrap", // Prevents wrapping
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                <label
                    htmlFor="tableName_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    {table ? "Source Table Name" : "Source Query"}
                  </label>
                  {migrationLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="tableName_input"
                      name="sourceTable"
                      className="w-full no-highlight"
                      value={
                        table
                          ? objectData?.data?.tableName
                          : objectData?.data?.queryText
                      }
                      readOnly
                      style={{
                        outline: "none" /* Removes the default outline */,
                        boxshadow: "none",
                        backgroundColor: "#dcdcdc",
                        border: "none",
                        color: "#000",
                        fontFamily: "Courier New",
                        overflow: "auto", // Allows scrolling
                        whiteSpace: "nowrap", // Prevents wrapping
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="grid w-full">
              <div className="col-12">
                <span className="text-lg font-bold uppercase">Target</span>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="dns_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    Target SFTP Details
                  </label>
                  {migrationLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="jobname"
                      name="jobname"
                      className="w-full no-highlight"
                      value={objectData?.data?.targetSecretName}
                      readOnly
                      style={{
                        outline: "none" /* Removes the default outline */,
                        boxshadow: "none",
                        backgroundColor: "#dcdcdc",
                        border: "none",
                        color: "#000",
                        fontFamily: "Courier New",
                        overflow: "auto", // Allows scrolling
                        whiteSpace: "nowrap", // Prevents wrapping
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="targetTable"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    File Path
                  </label>
                  {migrationLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="targetTable"
                      name="targetTable"
                      className="w-full no-highlight"
                      value={objectData?.data?.filePath}
                      readOnly
                      style={{
                        outline: "none" /* Removes the default outline */,
                        boxshadow: "none",
                        backgroundColor: "#dcdcdc",
                        border: "none",
                        color: "#000",
                        fontFamily: "Courier New",
                        overflow: "auto", // Allows scrolling
                        whiteSpace: "nowrap", // Prevents wrapping
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="targetTable"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    File Name
                  </label>
                  {migrationLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="targetTable"
                      name="targetTable"
                      className="w-full no-highlight"
                      value={objectData?.data?.fileName}
                      readOnly
                      style={{
                        outline: "none" /* Removes the default outline */,
                        boxshadow: "none",
                        backgroundColor: "#dcdcdc",
                        border: "none",
                        color: "#000",
                        fontFamily: "Courier New",
                        overflow: "auto", // Allows scrolling
                        whiteSpace: "nowrap", // Prevents wrapping
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {objectData.message !== null && objectData.message !== "" && (
          <div className="flex">
            <span className="font-bold text-lg">Note :</span>
            <span className="ml-1 text-lg"> {objectData?.message}</span>
          </div>
        )}
      </div>
    );
  };

  const footerConponent = (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="3"
          />
        </div>
      )}
      <Button
        label="Proceed"
        icon="pi pi-check"
        onClick={accept}
        autoFocus
        disabled={loading || migrationLoading}
      />
    </div>
  );

  // this is for the version control
  const [isCurrentVersion, setIsCurrentVersion] = useState(true);
  const versionOptions = Array.from(formData.versions);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [versionData, setVersionData] = useState({});
  const fetchVersionData = async (e) => {
    setSelectedVersion(e.value);
    // const axiosInstance = await myAxios();
    try {
      // let data;
      // if (viewRole === "DEV") {
      //   const resp = await axiosInstance.get(
      //     `dtm/sch/getVersion?ruleId=${formData.ruleId}&version=${e.value}`
      //   );
      //   data = resp.data;
      // } else {
      //   const resp = await axiosInstance.get(
      //     `${viewRole.toLowerCase()}/dtm/sch/getVersion?ruleId=${
      //       formData.ruleId
      //     }&version=${e.value}`
      //   );
      //   data = resp.data;
      //   // console.log("this i smount data", data);
      // }
      const data = await fetchVersionDataApidtm(viewRole, formData, e);
      setFormData((prevState) => ({
        ...prevState,
        ...data,
      }));
      setVersionData(data);
    } catch (error) {
      // console.log("error while getting version data")
      toast.current.show({
        severity: "error",
        summary: `Error while getting Version Data..`,
        detail: "Failed to load version..",
        life: 3000,
      });
    }
  };
  //showing the versino
  useEffect(() => {
    if (formData.version) {
      setSelectedVersion(formData.version);

      console.log("is current version", formData.version === ruleData.version);

      if (formData.version !== ruleData.version) {
        setIsCurrentVersion(false);
      } else {
        setIsCurrentVersion(true);
      }
    }
  }, [formData.version]);

  const handleReuseVersion = async (e) => {
    e.preventDefault();

    // const axiosInstance = await myAxios();
    try {
      // if (viewRole === "DEV") {
      //   await axiosInstance.post(`dtm/sch/setVersion`, formData);
      // } else {
      //   await axiosInstance.post(
      //     `${viewRole.toLowerCase()}/dtm/sch/setVersion`,
      //     formData
      //   );
      // }
      // console.log("thi is the form data", resp);
      // const encryptedData = await handleEncryptedRequest(versionData);
      // const publicKey = await getPublicKeyInstance();

      const encryptedData = await handleEncryptedData(versionData);

      await reuseVersionApidtm(viewRole, encryptedData);
      toast.current.show({
        severity: "success",
        summary: `Version ${formData.version} is in Use..`,
        detail: "Current Version Changed",
        life: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: `Faild to RoleBack the Version`,
        detail: "Version Rollback Failed",
        life: 2000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Breadcrumbs />

      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <div className="nameAndPush">
                <span className="text-2xl font-bold">
                  Database to Mount Transfer
                </span>

                <Dialog
                  header={errorMessage ? "Information" : "Confirmation"}
                  visible={confirmDialogVisible}
                  style={{ width: "40vw" }}
                  onHide={reject}
                  footer={!errorMessage ? footerConponent : null}
                  draggable={false}
                  resizable={false}
                >
                  {errorMessage ? (
                    <div className="text-center">
                      <h3>Status : {codeStatus}</h3>
                      <h3>{errorMessage}</h3>
                    </div>
                  ) : (
                    <>
                      <h3 className="ml-3 -mt-1">
                        <i
                          className="pi pi-exclamation-triangle mr-2"
                          style={{ fontSize: "2rem" }}
                        ></i>
                        Are you sure you want to proceed with{" "}
                        {selectedTransferDept.name}?
                      </h3>
                      {dataMigrate()}
                    </>
                  )}
                </Dialog>

                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {viewRole !== "PROD" && (
                    <div>
                      <Dropdown
                        value={selectedTransferDept}
                        onChange={async (e) => {
                          setSelectedTransferDept(e.value);
                          await jobMigrate();
                          //setconfirmDialogVisible(true);
                        }}
                        options={filteredOptions}
                        optionLabel="name"
                        placeholder="Migrate To:"
                        className="w-full migrateDrop"
                      />
                    </div>
                  )}
                  <div>
                    <Dropdown
                      value={selectedVersion}
                      onChange={(e) => {
                        fetchVersionData(e);
                      }}
                      options={versionOptions} // Directly pass the array of strings
                      placeholder="Version:"
                      className="w-full versionDrop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  required
                  invalid={errorFields.includes("jobname")}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 sm:col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select DataBase Details
                    </label>
                    <Dropdown
                      value={selectedDBOption || null}
                      onChange={handleDBSelectChange}
                      options={dbResponse || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Source Database"
                      className="w-full"
                      invalid={dbError.dbUrl}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="tableName_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="tableName_input"
                      name="tableName"
                      className="w-full"
                      value={formData.addQuery ? "" : formData.tableName}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                      disabled={queryChecked || formData.addQuery}
                      invalid={
                        dbError.tableName || errorFields.includes("tableName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="addQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="addQuery"
                      name="addQuery"
                      checked={queryChecked || formData.addQuery}
                      onChange={(e) => {
                        handleChange(e);
                        setQueryChecked(e.target.checked);

                        if (e.target.checked) {
                          setFormData((previous) => ({
                            ...previous,
                            tableName: "",
                          }));
                        }
                      }}
                    />
                  </div>

                  {(formData.addQuery || queryChecked) && (
                    <div className="group">
                      {limitErrorMessage && (
                        <p style={{ color: "red" }}>{limitErrorMessage}</p>
                      )}
                      <InputTextarea
                        name="queryText"
                        placeholder="Enter SQL query here"
                        className="w-full"
                        autoResize
                        value={formData.queryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                        invalid={
                          dbError.query || errorFields.includes("queryText")
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 2)}
                      className="border-round-lg w-full"
                      disabled={loading}
                    />
                  </div>
                </div>

                {/* <div className="col-12"></div> */}
              </div>
            </div>

            <div className="col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={sftpResponse || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Target SFTP"
                      className="w-full"
                      invalid={sftpError.server1}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path_input"
                      name="filePath"
                      className="w-full"
                      value={formData.filePath}
                      onChange={handleChange}
                      placeholder="Enter Target Path"
                      invalid={
                        sftpError.filePath || errorFields.includes("filePath")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File Name
                    </label>
                    <InputText
                      id="file_name_input"
                      name="fileName"
                      className="w-full"
                      value={formData.fileName}
                      onChange={handleChange}
                      placeholder="Enter File Name"
                      invalid={
                        sftpError.fileName || errorFields.includes("fileName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="encrypt"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Encryption
                    </label>
                    <Checkbox
                      inputId="encrypt"
                      name="encrypt"
                      checked={formData.encrypt}
                      onChange={handlePGPChecked}
                    />
                  </div>

                  {formData.encrypt && (
                    <div className="flex-auto">
                      <label
                        htmlFor="dns_input"
                        className="font-semibold text-xs block mx-1 mb-1 mt-3"
                      >
                        Select PGP Secret
                      </label>

                      <Dropdown
                        value={selectedPGPOption || null}
                        // onChange={handleSftpSelectChange}
                        // options={sftpResponse || []}
                        onChange={handlePGPSelectChange}
                        options={responsePGP || []}
                        // optionLabel="name"
                        // showClear
                        placeholder="Select a PGP Secret"
                        className="w-full"
                        invalid={errorFields.includes("pgpSecret")}
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Archive Path
                    </label>
                    <InputText
                      id="archiveLocation_input"
                      name="archiveLocation"
                      className="w-full"
                      value={formData.archiveLocation}
                      onChange={handleChange}
                      placeholder="Enter Archive Path"
                      invalid={errorFields.includes("archiveLocation")}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Jira Ticket Number
                    </label>
                    <InputText
                      id="path2_input"
                      name="jiraTicketNumber"
                      className="w-full"
                      value={formData.jiraTicketNumber}
                      onChange={handleChange}
                      placeholder="Enter Jira Ticket Number"
                      invalid={
                        sftpError.jiraTicketNumber ||
                        errorFields.includes("jiraTicketNumber")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 1)}
                      className="border-round-lg w-full"
                      disabled={loading}
                    />
                  </div>
                </div>
                <ConnectionTestDialog
                  visible={visible}
                  setVisible={setVisible}
                  loading={loading}
                  responseStatus={responseStatus}
                  responseMessage={responseMessage}
                />
              </div>
            </div>

            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
              <Button
                label="UseVersion"
                // type="submit"
                icon="pi pi-undo"
                // severity="primary"
                onClick={handleReuseVersion}
                className="border-round-lg"
                style={{
                  marginLeft: "30px",
                  backgroundColor: "#06b6d4",
                }}
                disabled={isCurrentVersion}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
