import React, { useState, useContext, useRef, useEffect } from "react";
import { ContextTimeScheduling } from "../components/scheduleComponent/ContextTimeScheduling";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Breadcrumbs from "../components/Breadcrumb";
import "primereact/resources/themes/saga-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css"; // Core Styles
import "primeicons/primeicons.css"; // Icons
import { createAxiosInstance as myAxios } from "../services/Helper";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { useUserContext } from "../components/contextAPIs/UserContext";
import { TestConnectionSFTPService } from "../API Services/TestConnectionService";
// import EncryptDataComponent from "../Encrypt API/Encrypt";
import { handleEncryptedData } from "../Encrypt API/ModifiedEncrypt";

import { checkEmptyField } from "../common/handleEmptyField";
import ConnectionTestDialog from "../Dialog/ConnectionTestDialog";

export default function SftpTransferFile() {
  // const { handleEncryptedRequest } = EncryptDataComponent();

  const navigate = useNavigate();
  const toast = useRef(null);
  const location = useLocation();
  const { response } = location.state || {};
  // console.log(response, "response");
  const { getRole } = useUserContext();
  const role = getRole();

  const [checked, setChecked] = useState(false);
  ///Here state for test connection
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  const { setOpenDialog, setcontextFormData } = useContext(
    ContextTimeScheduling
  );
  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedSftpOption2, setSelectedSftpOption2] = useState("");
  const [errorFields, setErrorFields] = useState([]);

  const [formData, setFormData] = useState({
    sourceSecretName: "",
    server1: "",
    port1: "",
    sftpUser1: "",
    sftpPass1: "",
    file1path: "",
    filepath1Copy: "",
    fileName: "",
    isfolder: false,
    filePattern: "",
    targetSecretName: "",
    server2: "",
    port2: "",
    sftpUser2: "",
    sftpPass2: "",
    file2path: "",
    jobname: "",
    archiveLocation: "",
    jiraTicketNumber: "",
  });

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  // added for test connection for sftp1
  // //Dropdown
  // const handleSftpSelectChange = (event) => {
  //   const selectedName = event.target.value;
  //   setSelectedSftpOption(selectedName);

  //   const selectedData = response.find(
  //     (item) => item.name === selectedName.name
  //   );
  //   if (selectedData) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       sourceSecretName: selectedName.name || "",
  //       server1: selectedData.data.find((e) => e.name === "host")?.value || "",
  //       port1: selectedData.data.find((e) => e.name === "port")?.value || "",
  //       sftpUser1:
  //         selectedData.data.find((e) => e.name === "username")?.value || "",
  //       sftpPass1:
  //         selectedData.data.find((e) => e.name === "password")?.value || "",
  //     }));
  //   }
  // };

  // Dropdown
  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = response.find(
      (item) => item.name === selectedName.name
    );

    if (selectedData) {
      const environmentData =
        role === "QA" ? selectedData["qa"] : selectedData["dev"];

      setFormData((prevState) => ({
        ...prevState,
        sourceSecretName: selectedName.name || "",
        server1: environmentData?.host || "", // accessing 'host' based on the environment
        port1: environmentData?.port || "", // accessing 'port'
        sftpUser1: environmentData?.username || "", // accessing 'username'
        sftpPass1: environmentData?.password || "", // accessing 'password'
      }));
    }
  };

  const handleSftpSelectChange2 = (event) => {
    const selectedName2 = event.target.value;
    setSelectedSftpOption2(selectedName2);

    const selectedData2 = response.find(
      (item) => item.name === selectedName2.name
    );
    if (selectedData2) {
      const environmentData =
        role === "QA" ? selectedData2["qa"] : selectedData2["dev"];
      setFormData((prevState) => ({
        ...prevState,
        targetSecretName: selectedName2.name || "",
        server2: environmentData?.host || "",
        port2: environmentData?.port || "",
        sftpUser2: environmentData?.username || "",
        sftpPass2: environmentData?.password || "",
      }));
    }
  };

  // console.log(formData);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (checked) {
      formData.fileName = "";
      formData.isfolder = true;
    } else {
      formData.isfolder = false;
      formData.filePattern = "";
    }
  }, [checked]);
  // validate form
  const validateForm = () => {
    const requiredFields = [
      "jobname",
      "sourceSecretName",
      "targetSecretName",
      "jiraTicketNumber",
      "file2path",
      "file1path",
      "archiveLocation",
    ];

    const notFilled = requiredFields.filter((e) => !formData[e]);
    setErrorFields(notFilled);
    if (!formData.isfolder && !formData.fileName) {
      setErrorFields((prev) => [...prev, "fileName"]);

      return false;
    }
    if (formData.isfolder && !formData.filePattern) {
      setErrorFields((prev) => [...prev, "filePattern"]);

      return false;
    }
    else if (notFilled.length > 0) {
      return false; // not all fields are filled
    }
    setErrorFields([]);
    return true; // All fields are filled
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all fields.",
        life: 3000,
      });
    }
  };

  const checkSftpValid = (serverData, btnNo) => {
    // console.log("sftpData",serverData);
    formData.filepath1Copy = formData.file1path;
    let errors = checkEmptyField(serverData);
    if (btnNo === 1 && !checked && formData.fileName.trim() === "")
      errors.fileName = "file name can't be empty";
    if (btnNo === 1 && formData.filepath1Copy.trim() === "")
      errors.filepath1Copy = "file path can't be empty";
    btnNo === 1 ? setsftpError(errors) : setsftpError2(errors);
    if (Object.keys(errors).length === 0) {
      callTestSFTP(serverData);
      setLoading(true);
      setVisible(true);
    } else return;
  };

  const callTestSFTP = async (serverData) => {
    try {
      // const encryptedData = await handleEncryptedRequest(serverData);
      const encryptedData = await handleEncryptedData(serverData);

      let sftp_connect = await TestConnectionSFTPService(encryptedData);
      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      setResponseMessage(message);
      setResponseStatus(status);
    } catch (e) {
      console.log(e);

      let errorResponse = e.response ? e.response : null;
      if (errorResponse == null) {
        setResponseMessage("Server Link Failed");
        setResponseStatus("500");

        setTimeout(() => {
          localStorage.clear();
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Network Error",
          detail: "Server Link Failure",
          life: 2000,
        });

        return;
      } else if (errorResponse.status === 401) {
        setResponseMessage("Session Expired ");
        setResponseStatus("401");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Session Timeout",
          detail: "Session Expired!, Try Login Again.",
          life: 2000,
        });
        return;
      } else {
        setResponseMessage(errorResponse.data.messages || "Failed to connect.");
        setResponseStatus(errorResponse.data.status || "Error");
      }
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  // const [sftpServer1, setSftpServer1] = useState({
  //   server1: "",port1: "",sftpUser1: "",sftpPass1: "",file1path: "",
  // });
  // const [sftpServer2, setSftpServer2] = useState({
  //   server2: "",port2: "",sftpUser2: "",sftpPass2: "",file2path: "",
  //   jiraTicketNumber:"",
  // });
  const [sftpServer1, setSftpServer1] = useState({
    server1: "",
    port1: "",
    user1: "",
    pass1: "",
    filePath: "",
    jiraTicketNumber: "",
  });

  const [sftpError, setsftpError] = useState({});
  const [sftpError2, setsftpError2] = useState({});
  const handleTestConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      const serverData = {
        server1: formData.server1,
        port1: formData.port1,
        user1: formData.sftpUser1,
        pass1: formData.sftpPass1,
        filePath: formData.file1path + "/" + formData.fileName,
      };

      setSftpServer1(serverData);
      checkSftpValid(serverData, buttonType);
    }
    // for second button
    else {
      const serverData = {
        server1: formData.server2,
        port1: formData.port2,
        user1: formData.sftpUser2,
        pass1: formData.sftpPass2,
        filePath: formData.file2path,
        jiraTicketNumber: formData.jiraTicketNumber,
      };
      setSftpServer1(serverData);
      checkSftpValid(serverData);
    }
  };

  return (
    <>
      <Breadcrumbs />

      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 sm:w-8 m-auto p-3">
          <div className="grid">
            <div className="col-12">
              <span className="text-2xl font-bold">
                Mount to Mount Transfer
              </span>
            </div>

            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name "
                  invalid={errorFields.includes("jobname")}
                />
              </div>
            </div>

            <div className="col-12 sm:col-2"></div>

            <div className="col-12 sm:col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={response || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Source SFTP"
                      className="w-full"
                      invalid={
                        sftpError?.server1 ||
                        errorFields.includes("sourceSecretName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path_input"
                      name="file1path"
                      className="w-full"
                      value={formData.file1path}
                      onChange={handleChange}
                      placeholder="Enter Source Path"
                      invalid={
                        sftpError.filepath1Copy ||
                        errorFields.includes("file1path")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="isfolder"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      All Files in the Folder
                    </label>
                    <Checkbox
                      inputId="isfolder"
                      name="isfolder"
                      value={formData.checked}
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File Name
                    </label>
                    <InputText
                      id="file_name_input"
                      name="fileName"
                      className="w-full"
                      value={formData.fileName}
                      onChange={handleChange}
                      placeholder="Enter File Name"
                      disabled={checked}
                      invalid={
                        (!checked && sftpError?.fileName) ||
                        errorFields.includes("fileName")
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File patterns
                    </label>
                    <InputText
                      id="file_Pattern"
                      name="filePattern"
                      className="w-full"
                      value={formData.filePattern}
                      onChange={handleChange}
                      placeholder="Enter File Patterns"
                      disabled={!checked}
                      invalid={checked && errorFields.includes("filePattern")}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="archiveLocation_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Archive Path
                    </label>
                    <InputText
                      id="archiveLocation_input"
                      name="archiveLocation"
                      className="w-full"
                      value={formData.archiveLocation}
                      onChange={handleChange}
                      placeholder="Enter Archive Path"
                      invalid={errorFields.includes("archiveLocation")}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2"></div>
            <div className="col-12 sm:col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption2 || null} // Ensuring selectedSftpOption is properly initialized
                      onChange={handleSftpSelectChange2}
                      options={response || []} // Default to an empty array if response is null or undefined
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Target SFTP"
                      className="w-full"
                      invalid={
                        sftpError2.server1 ||
                        errorFields.includes("targetSecretName")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path2_input"
                      name="file2path"
                      className="w-full"
                      value={formData.file2path}
                      onChange={handleChange}
                      placeholder="Enter Target Path"
                      invalid={
                        sftpError2.filePath || errorFields.includes("file2path")
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Jira Ticket Number
                    </label>
                    <InputText
                      id="path2_input"
                      name="jiraTicketNumber"
                      className="w-full"
                      value={formData.jiraTicketNumber}
                      onChange={handleChange}
                      placeholder="Enter Jira Ticket Number"
                      invalid={
                        sftpError2.jiraTicketNumber ||
                        errorFields.includes("jiraTicketNumber")
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 2)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
                <ConnectionTestDialog
                  visible={visible}
                  setVisible={setVisible}
                  loading={loading}
                  responseStatus={responseStatus}
                  responseMessage={responseMessage}
                />
              </div>
            </div>

            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
            </div>
          </div>
        </form>
      </div>
      <Toast ref={toast} />
    </>
  );
}