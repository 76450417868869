import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const getEditUserService = async (mail) => {
  const response = await axiosInstance.get(`users/edit/getuser/${mail}`);
  return response;
};

export const UpdateUserService = async (mail, encryptedData) => {
  let response = await axiosInstance.post(
    `users/updateUser/${mail}`,
    encryptedData
  );
  return response;
};

export const registerUserService = async (encryptedData) => {
  let response = await axiosInstance.post("users/register", encryptedData);
  // .then((res) => res)
  // .catch((e) => e.response);

  return response;
};
