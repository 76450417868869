import React, { useState, useContext, useEffect, useRef } from "react";
import "../css/rule.css";
import { ContextTimeScheduling } from "../../components/scheduleComponent/ContextTimeScheduling";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import Breadcrumbs from "../../components/Breadcrumb";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { viewRolesmtm, transferDept } from "./viewRoleApis";
import "../css/rule.css";
import {
  fetchVersionDataApimtm,
  migrateJobmtm,
  reuseVersionApimtm,
} from "../../API Services/RulesService/mtmServices";
import {
  TestConnectionSFTP2Service,
  TestConnectionSFTPService,
} from "../../API Services/TestConnectionService";
import { migrateJobValidation } from "../../API Services/RulesService/dtdServices";
// import EncryptDataComponent from "../../Encrypt API/Encrypt";

import { handleEncryptedData } from "../../Encrypt API/ModifiedEncrypt";

import { checkEmptyField } from "../../common/handleEmptyField";
import ConnectionTestDialog from "../../Dialog/ConnectionTestDialog";
import { Skeleton } from "primereact/skeleton";

export default function EditMountRules() {
  // const { handleEncryptedRequest } = EncryptDataComponent();
  const navigate = useNavigate();
  const [ruleData, setRuleData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [migrateLoading, setMigrateLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { ruleId, viewRole } = location.state || {};
  const [checked, setChecked] = useState(false);
  const toast = React.useRef(null);
  const [errorFields, setErrorFields] = useState([]);

  ///Here state for test connection

  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  //This state belongs to SFTP selection dropdwon
  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedSftpOption2, setSelectedSftpOption2] = useState("");
  const { response } = location.state || [];

  const [objectData, setObjectData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [codeStatus, setCodeStatus] = useState("");

  // console.log("Hello", response);
  // response.map((res) => console.log(res.name));
  useEffect(() => {
    // console.log(selectedViewAs+"this is the selected view as")
    if (ruleId === undefined) navigate("/home");
    setSelectedViewAs(viewRole);
    const fetchData = async () => {
      try {
        const axiosInstance = await myAxios();
        if (viewRole === "DEV") {
          const response = await axiosInstance.get(
            `${viewRolesmtm[0].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "QA") {
          const response = await axiosInstance.get(
            `${viewRolesmtm[1].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "PROD") {
          const response = await axiosInstance.get(
            `${viewRolesmtm[2].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        }
        // setCurrentVersion(response.data)
      } catch (error) {
        // console.log(error);
        // console.log(error.response);
        let errorValue = error.response ? error.response : null;
        // console.log(errorValue);
        if (errorValue == null) {
          navigate("/login");
        } else if (errorValue.status === 401) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Token Expired ",
            detail: "Session Time Out ",
            life: 2000,
          });
        } else if (errorValue.status === 403) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Access Denied",
            detail: "Try Login Again.",
            life: 2000,
          });
        }

        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (ruleId) {
      fetchData();
    }
  }, [ruleId]);

  const { setOpenDialog, setcontextFormData, setSelectedViewAs } = useContext(
    ContextTimeScheduling
  );
  const [formData, setFormData] = useState({
    sourceSecretName: "",
    server1: "",
    port1: "",
    sftpUser1: "",
    sftpPass1: "",
    file1path: "",
    filepath1Copy: "",
    fileName: "",
    jobname: "",
    isfolder: false,
    filePattern: "",
    targetSecretName: "",
    server2: "",
    port2: "",
    sftpUser2: "",
    sftpPass2: "",
    file2path: "",
    archiveLocation: "",
    // seconds: "",
    minutes: "",
    hours: "",
    months: "",
    days: "",
    weeks: "",
    frequencyType: "",
    // month: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    ruleId: "",
    jiraTicketNumber: "",
    version: "",
    versions: [],
  });

  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = response.find(
      (item) => item.name === selectedName.name
    );

    if (selectedData) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData["dev"];
      }

      // Set form data with the selected environment data
      setFormData((prevState) => ({
        ...prevState,
        sourceSecretName: selectedName.name || "",
        server1: environmentData?.host || "",
        port1: environmentData?.port || "",
        sftpUser1: environmentData?.username || "",
        sftpPass1: environmentData?.password || "",
      }));
    }
  };

  // console.log(formData);

  //Drop down handle change for the target
  const handleSftpSelectChange2 = (event) => {
    const selectedName2 = event.target.value;
    setSelectedSftpOption2(selectedName2);

    const selectedData2 = response.find(
      (item) => item.name === selectedName2.name
    );
    if (selectedData2) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData2["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData2["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData2["dev"];
      }
      setFormData((prevState) => ({
        ...prevState,
        targetSecretName: selectedName2.name || "",
        server2: environmentData?.host || "",
        port2: environmentData?.port || "",
        sftpUser2: environmentData?.username || "",
        sftpPass2: environmentData?.password || "",
      }));
    }
  };

  //////////////////////////////////
  // this function is used to set the data .
  // setSelectedSftpOption(selectedName);
  const setServerData = (secretName) => {
    response?.map((resp) => {
      if (resp.name === secretName) {
        setSelectedSftpOption(resp);
        // console.log(resp)
      }
    });
  };

  const setServerData2 = (secretName2) => {
    response?.map((resp) => {
      if (resp.name === secretName2) {
        setSelectedSftpOption2(resp);
        // console.log(resp)
      }
    });
  };

  //////////////////////////////////

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  const checkSftpValid = (serverData, btnNo) => {
    // console.log("sftpData",serverData);
    formData.filepath1Copy = formData.file1path;
    let errors = checkEmptyField(serverData);
    if (btnNo === 1 && !checked && formData.fileName.trim() === "")
      errors.fileName = "file name can't be empty";
    if (btnNo === 1 && formData.filepath1Copy.trim() === "")
      errors.filepath1Copy = "file path can't be empty";
    btnNo === 1 ? setsftpError(errors) : setsftpError2(errors);
    if (Object.keys(errors).length === 0) {
      callTestSFTP(serverData);
      setLoading(true);
      setVisible(true);
    } else return;
  };

  useEffect(() => {
    if (checked) {
      formData.fileName = "";
      formData.isfolder = true;
    } else {
      formData.isfolder = false;
      formData.filePattern = "";
    }
  }, [checked]);

  const callTestSFTP = async (sftpServer1) => {
    try {
      // const encryptedData = await handleEncryptedRequest(sftpServer1);

      // const encryptedData = await handleEncryptedData(sftpServer1);

      // let sftp_connect = await TestConnectionSFTPService(encryptedData);
      let sftp_connect = await TestConnectionSFTP2Service(sftpServer1);
      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      setResponseMessage(message);
      setResponseStatus(status);
    } catch (e) {
      console.log(e);
      let errorResponse = e.response ? e.response : null;
      if (errorResponse == null) {
        setResponseMessage("Server Link Failed");
        setResponseStatus("500");

        setTimeout(() => {
          localStorage.clear();
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Network Error",
          detail: "Server Link Failure",
          life: 2000,
        });

        return;
      } else if (errorResponse.status === 401) {
        setResponseMessage("Session Expired ");
        setResponseStatus("401");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Session Timeout",
          detail: "Session Expired!, Try Login Again.",
          life: 2000,
        });
        return;
      } else {
        setResponseMessage(errorResponse.data.messages || "Failed to connect.");
        setResponseStatus(errorResponse.data.status || "Error");
      }
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  const [sftpError, setsftpError] = useState({});
  const [sftpError2, setsftpError2] = useState({});
  const handleTestConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      const serverData = {
        server1: formData.server1,
        port1: formData.port1,
        user1: formData.sftpUser1,
        pass1: formData.sftpPass1,
        filePath: formData.file1path + "/" + formData.fileName,
      };
      checkSftpValid(serverData, buttonType);
    }
    // for second button
    else {
      const serverData = {
        server1: formData.server2,
        port1: formData.port2,
        user1: formData.sftpUser2,
        pass1: formData.sftpPass2,
        filePath: formData.file2path,
        ...(ruleData.jiraTicketNumber !== formData.jiraTicketNumber && {
          jiraTicketNumber: formData.jiraTicketNumber,
        }),
      };
      checkSftpValid(serverData);
    }
  };

  ///////////////////////////////////////////////////////

  useEffect(() => {
    if (ruleData) {
      // const cronValues = parseCronExpression(ruleData.cronExpression);
      // set the check value .
      setChecked(ruleData.isfolder);
      // console.log(ruleData, "Ruledata");

      // Extract time part from startDate
      setServerData(ruleData?.sourceSecretName);
      setServerData2(ruleData?.targetSecretName);

      const startTime = ruleData?.startDate
        ? ruleData.startDate.split("T")[1]
        : "";
      setFormData({
        sourceSecretName: ruleData.sourceSecretName || "",
        server1: ruleData.sftpHost1 || "",
        port1: ruleData.port1 || "",
        sftpUser1: ruleData.sftpUser1 || "",
        sftpPass1: ruleData.password1 || "",
        file1path: ruleData.sourceLocation || "",
        jobname: ruleData.jobname || "",
        filePattern: ruleData.filePattern || "",
        fileName: ruleData.fileName || "",
        targetSecretName: ruleData.targetSecretName || "",
        server2: ruleData.sftpHost2 || "",
        port2: ruleData.port2 || "",
        isfolder: ruleData.isfolder || false,
        sftpUser2: ruleData.sftpUser2 || "",
        sftpPass2: ruleData.password2 || "",
        file2path: ruleData.targetLocation || "",
        archiveLocation: ruleData.archiveLocation || "",
        months: ruleData.months || "",
        hours: ruleData.hours || "",
        minutes: ruleData.minutes || "",
        weeks: ruleData.weeks || "",
        days: ruleData.days || "",
        frequencyType: ruleData.frequencyType || "",
        // ...cronValues,
        timeZone: ruleData.timeZone || "",
        startDate: ruleData.startDate || "",
        startTime: startTime || "", // Set startTime here
        endDate: ruleData.endDate || "",
        ruleId: ruleData.id || "",
        jiraTicketNumber: ruleData.jiraTicketNumber || "",
        version: ruleData.version || "",
        versions: ruleData.versions || "",
      });
    }
  }, [ruleData]);

  // console.log(formData, "Kuna");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  ///////////////////////////////////////////////////////////

  // validate form
  const validateForm = () => {
    const requiredFields = [
      "jobname",
      "sourceSecretName",
      "targetSecretName",
      "jiraTicketNumber",
      "file2path",
      "file1path",
      "archiveLocation",
    ];

    const notFilled = requiredFields.filter((e) => !formData[e]);
    if (notFilled.length > 0) {
      // console.log(notFilled, " error value s");
      setErrorFields(notFilled);
      return false; // not all fields are filled
    }
    if (formData.isfolder && !formData.filePattern) {
      setErrorFields((prev) => [...prev, "filePattern"]);

      return false;
    } else if (!formData.isfolder && !formData.fileName) {
      setErrorFields((prev) => [...prev, "fileName"]);
      return false;
    }
    setErrorFields([]);
    return true; // All fields are filled
  };

  // need to update here for the fileName;
  const handleClick = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    } else {
      // console.log(errorFields);

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all fields.",
        life: 3000,
      });
    }
  };

  ////////////////////////////////////////////////////////////

  // const for the data migration status role
  const [selectedTransferDept, setSelectedTransferDept] = useState("");

  // const { getRole } = useUserContext();

  const filteredOptions = transferDept.filter((option) => {
    if (viewRole === "DEV") return option.code === "qa";
    if (viewRole === "QA") return option.code === "prod";
    return true; // Show all options for admin or other roles
  });

  const accept = async () => {
    setLoading(true);
    try {
      // const axiosInstance = await myAxios();
      // await axiosInstance
      //   .post(
      //     `/moveto${selectedTransferDept.name.toLowerCase()}/mtm`,
      //     objectData?.data
      //   )
      // const encryptedData = await handleEncryptedRequest(objectData);
      const { data } = objectData;
      const encryptedData = await handleEncryptedData(data);

      await migrateJobmtm(selectedTransferDept, encryptedData)
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Confirmed",
            detail: `Job Migrated to ${selectedTransferDept.name}`,
            life: 3000,
          });
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: `Error While Migration`,
            detail: `${e.response.data}`,
            life: 3000,
          });
        });
      setSelectedTransferDept("");
      setconfirmDialogVisible(false);
    } catch {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error while migrating to ${selectedTransferDept.name}`,
        life: 3000,
      });
      setSelectedTransferDept("");
    } finally {
      setLoading(false);
    }
  };

  const reject = () => {
    setSelectedTransferDept("");
    setconfirmDialogVisible(false);
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "Request Cancalled",
      life: 3000,
    });
    setObjectData({});
    setErrorMessage("");
    setCodeStatus("");
  };
  const [confirmDialogVisible, setconfirmDialogVisible] = useState(false);

  const jobMigrate = async () => {
    // const axiosInstance = await myAxios();
    try {
      // const response = await axiosInstance.post(
      //   `/moveto${filteredOptions[0].code}validation?id=${ruleId}&move="moving"&approve="pending"`
      // );
      setconfirmDialogVisible(true);
      setMigrateLoading(true);
      const response = await migrateJobValidation(filteredOptions, ruleId);
      setObjectData(response.data);
      // console.log(response);
    } catch (err) {
      console.log(err);
      if (err.response?.data?.status === 404) {
        setErrorMessage(err.response?.data?.messages);
        setCodeStatus(err.response?.data?.status);
        setconfirmDialogVisible(true);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An Error Occured",
          life: 3000,
        });
        setconfirmDialogVisible(false);
      }
      setSelectedTransferDept("");
    } finally {
      setMigrateLoading(false);
    }
  };

  // console.log(codeStatus, "hello");

  const dataMigrate = () => {
    const inputStyle = {
      outline: "none", // Removes the default outline
      boxShadow: "none",
      backgroundColor: "#dcdcdc",
      border: "none",
      color: "#000",
      fontFamily: "Courier New",
      overflow: "auto", // Allows scrolling
      whiteSpace: "nowrap", // Prevents wrapping
    };
    return (
      <div>
        <div className="col-12 sm:col-11">
          <div className="flex-auto">
            <label
              htmlFor="jobname"
              className="font-semibold text-xs block mx-1 mb-1"
            >
              Job Name
            </label>
            {migrateLoading ? (
              <Skeleton width="100%" height="2rem" />
            ) : (
              <InputText
                id="jobname"
                name="jobname"
                className="w-full no-highlight"
                value={objectData?.data?.jobname}
                readOnly
                style={inputStyle}
              />
            )}
          </div>
        </div>

        <div className="grid col-12">
          <div className="col-6">
            <div className="grid">
              <div className="col-12">
                <span className="text-lg font-bold uppercase">Source</span>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="dns_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    Source SFTP Details
                  </label>
                  {migrateLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="jobname"
                      name="jobname"
                      className="w-full no-highlight"
                      value={objectData?.data?.sourceSecretName}
                      readOnly
                      style={inputStyle}
                    />
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="tableName_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    File Path
                  </label>
                  {migrateLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="tableName_input"
                      name="sourceTable"
                      className="w-full no-highlight"
                      value={objectData?.data?.sourceLocation}
                      readOnly
                      style={inputStyle}
                    />
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="dns_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    File Name
                  </label>
                  {migrateLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="jobname"
                      name="jobname"
                      className="w-full no-highlight"
                      value={objectData?.data?.fileName}
                      readOnly
                      style={inputStyle}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="grid w-full">
              <div className="col-12">
                <span className="text-lg font-bold uppercase">Target</span>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="dns_input"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    Target SFTP Details
                  </label>
                  {migrateLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="jobname"
                      name="jobname"
                      className="w-full no-highlight"
                      value={objectData?.data?.targetSecretName}
                      readOnly
                      style={inputStyle}
                    />
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="flex-auto">
                  <label
                    htmlFor="targetTable"
                    className="font-semibold text-xs block mx-1 mb-1"
                  >
                    File Path
                  </label>
                  {migrateLoading ? (
                    <Skeleton width="100%" height="2rem" />
                  ) : (
                    <InputText
                      id="targetTable"
                      name="targetTable"
                      className="w-full no-highlight"
                      value={objectData?.data?.targetLocation}
                      readOnly
                      style={inputStyle}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {objectData.message !== null && objectData.message !== "" && (
          <div className="flex">
            <span className="font-bold text-lg">Note :</span>
            <span className="ml-1 text-lg"> {objectData?.message}</span>
          </div>
        )}
      </div>
    );
  };

  const footerConponent = (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="3"
          />
        </div>
      )}
      <Button
        label="Proceed"
        icon="pi pi-check"
        onClick={accept}
        autoFocus
        disabled={loading || migrateLoading}
      />
    </div>
  );

  // this is for the version control
  const [isCurrentVersion, setIsCurrentVersion] = useState(true);
  const versionOptions = Array.from(formData.versions);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [versionData, setVersionData] = useState({});
  const fetchVersionData = async (e) => {
    setSelectedVersion(e.value);
    // const axiosInstance = await myAxios();
    try {
      // let data;
      // if (viewRole === "DEV") {
      //   const resp = await axiosInstance.get(
      //     `mtm/sch/getVersion?ruleId=${formData.ruleId}&version=${e.value}`
      //   );
      //   data = resp.data;
      // } else {
      //   const resp = await axiosInstance.get(
      //     `${viewRole.toLowerCase()}/mtm/sch/getVersion?ruleId=${
      //       formData.ruleId
      //     }&version=${e.value}`
      //   );
      //   data = resp.data;
      //   console.log("this i smount data", data);
      // }
      const data = await fetchVersionDataApimtm(viewRole, formData, e);
      setFormData((prevState) => ({
        ...prevState,
        ...data,
      }));
      setVersionData(data);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: `Error while getting Version Data`,
        detail: "Error",
        life: 2000,
      });
    }
  };
  //showing the versino
  useEffect(() => {
    if (formData.version) {
      setSelectedVersion(formData.version);

      // console.log("is current version", formData.version === ruleData.version);

      if (formData.version !== ruleData.version) {
        setIsCurrentVersion(false);
      } else {
        setIsCurrentVersion(true);
      }
    }
  }, [formData.version]);

  const handleReuseVersion = async (e) => {
    e.preventDefault();

    // const axiosInstance = await myAxios();
    try {
      // if (viewRole === "DEV") {
      //   await axiosInstance.post(`mtm/sch/setVersion`, formData);
      // } else {
      //   await axiosInstance.post(
      //     `${viewRole.toLowerCase()}/mtm/sch/setVersion`,
      //     formData
      //   );
      // }
      // const encryptedData = await handleEncryptedRequest(versionData);

      const encryptedData = await handleEncryptedData(versionData);

      await reuseVersionApimtm(viewRole, encryptedData);
      // console.log("thi is the form data", resp);
      toast.current.show({
        severity: "success",
        summary: `Version ${formData.version} is in Use..`,
        detail: "Current Version Changed",
        life: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: `Faild to RoleBack the Version`,
        detail: "Version Rollback Failed",
        life: 2000,
      });
    }
  };

  // version view end here
  return (
    <>
      <Toast ref={toast} />
      <Breadcrumbs />

      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <div className="nameAndPush">
                <span className="text-2xl font-bold">
                  Mount to Mount Transfer
                </span>

                <Dialog
                  header={errorMessage ? "Information" : "Confirmation"}
                  visible={confirmDialogVisible}
                  style={{ width: "40vw" }}
                  onHide={reject}
                  footer={!errorMessage ? footerConponent : null}
                  draggable={false}
                  resizable={false}
                >
                  {errorMessage ? (
                    <div className="text-center">
                      <h3>Status : {codeStatus}</h3>
                      <h3>{errorMessage}</h3>
                    </div>
                  ) : (
                    <>
                      <h3 className="ml-3 -mt-1">
                        <i
                          className="pi pi-exclamation-triangle mr-2"
                          style={{ fontSize: "2rem" }}
                        ></i>
                        Are you sure you want to proceed with{" "}
                        {selectedTransferDept.name}?
                      </h3>
                      {dataMigrate()}
                    </>
                  )}
                </Dialog>

                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {viewRole !== "PROD" && (
                    <div>
                      <Dropdown
                        value={selectedTransferDept}
                        onChange={async (e) => {
                          setSelectedTransferDept(e.value);
                          await jobMigrate();

                          // setconfirmDialogVisible(true);
                        }}
                        options={filteredOptions}
                        optionLabel="name"
                        placeholder="Migrate To:"
                        className="w-full migrateDrop"
                      />
                    </div>
                  )}
                  <div>
                    <Dropdown
                      value={selectedVersion}
                      onChange={(e) => {
                        fetchVersionData(e);
                      }}
                      options={versionOptions} // Directly pass the array of strings
                      placeholder="Version:"
                      className="w-full versionDrop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  required
                  invalid={errorFields.includes("jobname") || false}
                  disabled
                />
              </div>
            </div>{" "}
            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={response || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Source SFTP"
                      className="w-full"
                      invalid={
                        sftpError?.server1 ||
                        errorFields.includes("sourceSecretName") ||
                        false
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path_input"
                      name="file1path"
                      className="w-full"
                      value={formData.file1path}
                      onChange={handleChange}
                      placeholder="Enter Source Path"
                      invalid={
                        sftpError.filepath1Copy ||
                        errorFields.includes("file1path") ||
                        false
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="isfolder"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      All Files in the Folder
                    </label>
                    <Checkbox
                      inputId="isfolder"
                      name="isfolder"
                      value={formData.isfolder}
                      onChange={(e) => setChecked(e.checked)}
                      // onChange={handleChange}
                      checked={checked}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File Name
                    </label>
                    <InputText
                      id="file_name_input"
                      name="fileName"
                      className="w-full"
                      value={formData.fileName}
                      onChange={handleChange}
                      placeholder="Enter File Name"
                      disabled={checked}
                      invalid={
                        (!checked && sftpError?.fileName) ||
                        errorFields.includes("fileName") ||
                        false
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File patterns
                    </label>
                    <InputText
                      id="file_Pattern"
                      name="filePattern"
                      className="w-full"
                      value={formData.filePattern}
                      onChange={handleChange}
                      placeholder="Enter File Patterns"
                      disabled={!checked}
                      invalid={checked && errorFields.includes("filePattern")}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="archiveLocation_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Archive Path
                    </label>
                    <InputText
                      id="archiveLocation_input"
                      name="archiveLocation"
                      className="w-full"
                      value={formData.archiveLocation}
                      onChange={handleChange}
                      placeholder="Enter Archive Path"
                      invalid={errorFields.includes("archiveLocation") || false}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption2 || null} // Ensuring selectedSftpOption is properly initialized
                      onChange={handleSftpSelectChange2}
                      options={response || []} // Default to an empty array if response is null or undefined
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Target SFTP"
                      className="w-full"
                      invalid={
                        sftpError2?.server1 ||
                        errorFields.includes("targetSecretName") ||
                        false
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path2_input"
                      name="file2path"
                      className="w-full"
                      value={formData.file2path}
                      onChange={handleChange}
                      placeholder="Enter Target Path"
                      invalid={
                        sftpError2.filePath ||
                        errorFields.includes("file2path") ||
                        false
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Jira Ticket Number
                    </label>
                    <InputText
                      id="path2_input"
                      name="jiraTicketNumber"
                      className="w-full"
                      value={formData.jiraTicketNumber}
                      onChange={handleChange}
                      placeholder="Enter Jira Ticket Number"
                      invalid={sftpError2.jiraTicketNumber || false}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 2)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
                <ConnectionTestDialog
                  visible={visible}
                  setVisible={setVisible}
                  loading={loading}
                  responseStatus={responseStatus}
                  responseMessage={responseMessage}
                />
              </div>
            </div>
            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
              <Button
                label="UseVersion"
                // type="submit"
                icon="pi pi-undo"
                // severity="primary"
                onClick={handleReuseVersion}
                className="border-round-lg"
                style={{
                  marginLeft: "30px",
                  backgroundColor: "#06b6d4",
                }}
                disabled={isCurrentVersion}
              />
            </div>
          </div>

          <div className="row m-auto"></div>
        </form>
      </div>
    </>
  );
}
